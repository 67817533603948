(function ($, app, _) {
  'use strict';
  app.m37 = function () {
    $('.show-more-text').click(function () {
      var $showMoreBtn = $('.sidebar-show-more-btn');
      var val = $(this).text();
      if (val == 'Mehr zeigen') {
        $('.cv-listing-details').css('height', 'auto');
        $(this).text('Weniger zeigen');
        $($showMoreBtn).toggleClass('sidebar-show-more-btn--rotate');
      } else {
        $('.cv-listing-details').css('height', '96px');
        $(this).text('Mehr zeigen');
        $($showMoreBtn).removeClass('sidebar-show-more-btn--rotate');
      }
      return false;
    });
  };
})(jQuery, app, _);
