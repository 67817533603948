(function ($, app, _) {
  'use strict';
  app.m53 = function () {

    if( $('section.m53').length == 0 ) return;

    let options = {
      valueNames: [ 
        { data: ['id'] },
        { data: ['sorttitle'] },
        { data: ['sortparticipation'] },
        { data: ['search'] },
        { data: ['date'] },
        { data: ['status'] },
        { data: ['participation'] },
        { data: ['topic'] },
        { data: ['target'] },
        { data: ['location'] },
        { data: ['funding'] }
      ]
    };

    let options_filter        = { itemSelectText: '', shouldSort: false, searchFields: ['label'] }

    let projects              = new List('m53_projects', options);
    let choices_status        = new Choices($('select[name="ois-filter-status"]')[0],        options_filter)
    let choices_participation = new Choices($('select[name="ois-filter-participation"]')[0], options_filter)
    let choices_topic         = new Choices($('select[name="ois-filter-topic"]')[0],         options_filter)
    let choices_target        = new Choices($('select[name="ois-filter-target"]')[0],        options_filter)
    let choices_location      = new Choices($('select[name="ois-filter-location"]')[0],      options_filter)
    let choices_funding       = new Choices($('select[name="ois-filter-funding"]')[0],       options_filter)

    projects.sort('sortparticipation', { 'order': 'asc' });
    filterProjects();
    setTimeout( updateUI, 10 );
    $('section.m53 .sidebar').removeClass('loading')

    $('input[name="ois-search"]').on('keyup', function() {
      filterProjects();
      setTimeout( updateUI, 10 );
    });

    $('select[name^="ois-filter-"]').on('change', function() {
      filterProjects();
      setTimeout( updateUI, 10 );
    });

    $('.m53 .reset').on('click', function() {
      // reset fields
      $('input[name="ois-search"]').val('')
      choices_status.setChoiceByValue('') 
      choices_participation.setChoiceByValue('') 
      choices_topic.setChoiceByValue('') 
      choices_target.setChoiceByValue('') 
      choices_location.setChoiceByValue('') 
      choices_funding.setChoiceByValue('') 
      
      // reset filter
      projects.filter();
      projects.search();
      
      // update counter
      setTimeout( updateUI, 10 );
    });

    $('.m53 .sidebar_toggle').on('click', function() {
      $('.sidebar').toggleClass('showForm');
    });

    function filterProjects() {
      let val_search        = $('input[name="ois-search"]').val().toLowerCase()
      let val_status        = $('select[name="ois-filter-status"]').val()
      let val_participation = $('select[name="ois-filter-participation"]').val()
      let val_topic         = $('select[name="ois-filter-topic"]').val()
      let val_target        = $('select[name="ois-filter-target"]').val()
      let val_location      = $('select[name="ois-filter-location"]').val()
      let val_funding       = $('select[name="ois-filter-funding"]').val()
      
      projects.filter(function(item) { 
        if( item.values().search        != '*' && item.values().search.toLowerCase().indexOf(val_search)                == -1 ) return false
        if( item.values().status        != '*' && item.values().status.indexOf(val_status)                              == -1 ) return false
        if( item.values().topic         != '*' && item.values().topic.indexOf(val_topic)                                == -1 ) return false
        if( item.values().target        != '*' && item.values().target.indexOf(val_target)                              == -1 ) return false
        if( item.values().location      != '*' && item.values().location.indexOf(val_location)                          == -1 ) return false
        if( item.values().funding       != '*' && item.values().funding.indexOf(val_funding)                            == -1 ) return false
        if( item.values().participation != '*' && val_participation != '' && item.values().participation != val_participation ) return false
        
        return true
      }); 
    }

    function updateUI() {
      // reset chapter visibility to fix calculations
      $('.project__chapter').show()

      let dummies          = $('.project__chapter').length
      let participateItems = $('.project.take-part').length
      let visibleItems     = projects.visibleItems.length - dummies
      let projectItems     = projects.items.length - dummies

      let html = ( visibleItems == projectItems ) ? visibleItems + ' projects' : visibleItems + ' of ' + projectItems + ' projects'

      if( document.documentElement.lang == 'de-DE' ) {
        html = ( visibleItems == projectItems ) ? visibleItems + ' Projekte' : visibleItems + ' von ' + projectItems + ' Projekte'
      } 

      // update project count
      $('.sidebar .count').html( html )
      
      // show / hide headlines dynamically
      $('.project__chapter.take-part').toggle( participateItems > 0 )
      $('.project__chapter.cant-take-part').toggle( (visibleItems - participateItems) > 0 )

      // pick correct label for headline
      $('.project__chapter span.h_more').toggle( participateItems > 0 )
      $('.project__chapter span.h_projects').toggle( participateItems <= 0 )

      // show / hide "no projects found" message
      $('.no-projects-found').toggle( visibleItems <= 0 )
    }
  };
})(jQuery, app, _);
