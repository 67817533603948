(function ($, app, _) {
  'use strict';
  app.m49 = function () {

    if( $('section.m49').length == 0 ) return;

    let options = {
      valueNames: [ 
        'title', 
        { data: ['id'] },
        { data: ['authors'] },
        { data: ['sorttitle'] },
        { data: ['date'] },
        { data: ['year'] },
        { data: ['topic'] },
        { data: ['location'] },
        { data: ['focus'] },
        { data: ['method'] },
        { data: ['stakeholders'] }
      ]
    };


    let options_filter      = { itemSelectText: '', shouldSort: false, searchFields: ['label'] }
    let options_sort        = { itemSelectText: '', shouldSort: false, searchEnabled: false }

    let projects             = new List('m49_projects', options);
    let choices_sort         = new Choices($('select[name="ois-sort"]')[0], options_sort)
    let choices_topic        = new Choices($('select[name="ois-filter-topic"]')[0], options_filter)
    let choices_year         = new Choices($('select[name="ois-filter-year"]')[0], options_filter)
    let choices_location     = new Choices($('select[name="ois-filter-location"]')[0], options_filter)
    let choices_stakeholders = new Choices($('select[name="ois-filter-stakeholders"]')[0], options_filter)
    let choices_method       = new Choices($('select[name="ois-filter-method"]')[0], options_filter)

    projects.sort('date', { 'order': 'desc' });
    filterProjects();
    setTimeout( updateCount, 10 );

    $('select[name="ois-sort"]').on('change', function() {
      let value = $(this).val()
      let order = ( value == 'date' ) ? 'desc' : 'asc'

      projects.sort(value, { 'order': order });
      setTimeout( updateCount, 10 );
    });

    $('input[name="ois-search"]').on('keyup', function() {
      filterProjects();
      setTimeout( updateCount, 10 );
    });

    $('select[name^="ois-filter-"]').on('change', function() {
      filterProjects();
      setTimeout( updateCount, 10 );
    });

    $('.m49 .reset').on('click', function() {
      // reset fields
      $('input[name="ois-search"]').val('')
      choices_sort.setChoiceByValue('') 
      choices_topic.setChoiceByValue('') 
      choices_year.setChoiceByValue('') 
      choices_location.setChoiceByValue('') 
      choices_stakeholders.setChoiceByValue('') 
      choices_method.setChoiceByValue('') 
      
      // reset filter
      projects.filter();
      projects.search();
      
      // reset sort
      projects.sort('date', { 'order': 'desc' });

      // update counter
      setTimeout( updateCount, 10 );
    });

    $('.m49 .toggleAll').on('click', function() {
      $('.projects .list').addClass('showAll');
    });

    $('.m49 .sidebar_toggle').on('click', function() {
      $('.sidebar').toggleClass('showForm');
    });

    $('.m49 .exportXLS').on('click', function() {
      let f   = $("<form target='_blank' method='POST' action='/export/ois/database/xls.php' style='display:none;'></form>").appendTo(document.body)
      let ids = 'all'

      if( projects.visibleItems.length != projects.items.length ) {
        ids = []
        for( let item in projects.visibleItems) { ids.push(projects.visibleItems[item]._values.id) }
        ids = ids.join(';')
      }
      
      $('<input type="hidden" name="ids" value="' + ids + '" />').appendTo(f);
      f.submit().remove();
    });

    function filterProjects() {
      let val_search       = prepareSearchString( $('input[name="ois-search"]').val() )
      let val_topic        = $('select[name="ois-filter-topic"]').val()
      let val_year         = $('select[name="ois-filter-year"]').val()
      let val_location     = $('select[name="ois-filter-location"]').val()
      let val_method       = $('select[name="ois-filter-method"]').val()
      let val_stakeholders = $('select[name="ois-filter-stakeholders"]').val()

      
      
      projects.filter(function(item) { 
        let str_search = prepareSearchString( item.values().title + ' ' + item.values().authors )
        
        if( str_search.indexOf(val_search)                        == -1 ) return false
        if( item.values().topic.indexOf(val_topic)                == -1 ) return false
        if( item.values().year.indexOf(val_year)                  == -1 ) return false
        if( item.values().location.indexOf(val_location)          == -1 ) return false
        if( item.values().method.indexOf(val_method)              == -1 ) return false
        if( item.values().stakeholders.indexOf(val_stakeholders)  == -1 ) return false
        
        return true
      }); 
    }

    function prepareSearchString( string ) {
      return string.toLowerCase()
    }

    function updateCount() {
      let html = ( projects.visibleItems.length == projects.items.length ) ? projects.visibleItems.length + ' projects' : projects.visibleItems.length + ' of ' + projects.items.length + ' projects'
      $('.sidebar .count').html( html )
      $('.toggleAll').toggle( projects.visibleItems.length > 4 )
      $('.no-projects-found').toggle( projects.visibleItems.length <= 0 )
    }
  };
})(jQuery, app, _);
