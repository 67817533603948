(function ($, app, _) {
  'use strict';
  app.m06 = function () {
    $('.m06__links a').on('click', function (e) {
      e.preventDefault();
      scrollToAnker( $(this), 'link' );
    });

    $('.wysiwyg a[href^="#"]').on('click', function (e) {
      e.preventDefault();
      scanforAnker( $(this).attr('href') );
    });

    $('.m06__select select').on('change', function (e) {
      e.preventDefault();
      scrollToAnker( $(this), 'select' );
    });

    // if hash exists, scroll to anker
    if( window.location.hash ) scanforAnker( window.location.hash )

    function scanforAnker( hash ) {
      $('.m06__links a').each(function() {
        if ( hash == $( this ).attr('href') ) scrollToAnker( $(this), 'link') 
      });
      
      $('.m06__select option').each(function() {
        if ( hash == $( this ).attr('value') ) scrollToAnker( $(this), 'option') 
      });
    }

    function scrollToAnker( $el, type ) {
      var splitKey  = $el.attr('as-link') == 'm06--link' ? '----' : '-';
      var link      = (type == 'select') ? $el.find("option:selected").attr('data-block-id').split(splitKey) : $el.attr('data-block-id').split(splitKey);
      var className = link[0].substring(1);
      var index     = parseInt(link[link.length - 1]);
      var top       = index >= 1 ? $($('.' + className)[index]).offset().top : $('.' + className).offset().top;

      // set hash
      if(type == 'link'  ) window.location.hash = $el.attr('href');
      if(type == 'select') window.location.hash = $el.find("option:selected").attr('value');
      if(type == 'option') window.location.hash = $el.attr('value');

      // scroll to target
      $('html, body').animate({scrollTop: top - 60},500);

    }
  };
})(jQuery, app, _);
