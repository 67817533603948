(function ($, app, _) {
  'use strict';
  app.m09 = function () {
    //Sample contents
    var contents = $('#preMain .year')
      .map(function (i, el) {
        return $(el).html();
      })
      .get();

    var mainContent = $('#preMain .item')
      .map(function (i, el) {
        return $(el).html();
      })
      .get();

    $('#mainCont-track').width(750 * $('#preMain .year').length);

    //Main function. Draw your circles.
    function makeCircles() {
      var lastInt = (contents[contents.length - 1] - contents[0]) * 12;
      //Forget the timeline if there's only one date. Who needs it!?
      if (contents.length < 2) {
        $('#line').hide();
        $('#span').show().text(contents[0]);
        //This is what you really want.
      } else if (contents.length >= 2) {
        //Set day, month and year variables for the math

        //Draw first date circle
        $('#line').append(
          '<div class="circle" id="circle0" style="left: ' +
            0 +
            '%;"><div class="popupSpan">' +
            contents[0] +
            '</div></div>'
        );

        $('#mainCont-track').append(
          '<span id="span0" class="center">' + mainContent[0] + '</div>'
        );
        let i = 1;
        //Loop through middle contents
        for (i; i < contents.length - 1; i++) {
          var relativeInt = (contents[i] - contents[0]) * 12;
          var percent = (relativeInt * 100) / lastInt;
          //Draw the date circle
          $('#line').append(
            '<div class="circle" id="circle' +
              i +
              '" style="left: ' +
              percent +
              '%;"><div class="popupSpan">' +
              contents[i] +
              '</div></div>'
          );

          $('#mainCont-track').append(
            '<span id="span' +
              i +
              '" class="right">' +
              mainContent[i] +
              '</div>'
          );
        }

        //Draw the last date circle
        $('#line').append(
          '<div class="circle" id="circle' +
            i +
            '" style="left: ' +
            99 +
            '%;"><div class="popupSpan">' +
            contents[contents.length - 1] +
            '</div></div>'
        );

        $('#mainCont-track').append(
          '<span id="span' + i + '" class="right">' + mainContent[i] + '</div>'
        );
      }

      $('.circle:first').addClass('active');
    }

    makeCircles();

    $('.circle').mouseenter(function () {
      $(this).addClass('hover');
    });

    $('.circle').mouseleave(function () {
      $(this).removeClass('hover');
    });

    $('.circle').click(function () {
      var spanNum = $(this).attr('id');
      selectDate(spanNum);
    });

    $('#prevYear').on('click', function (el) {
      $('#nextYear').removeClass('disabled');
      $(this).removeClass('disabled');
      const currentPos = $('.center').attr('id').replace('span', '');
      const nextPost = parseInt(currentPos) - 1;

      if (currentPos == 0) {
        $(this).addClass('disabled');
        return;
      }

      if (nextPost == 0) {
        $(this).addClass('disabled');
      }

      $('.center').removeClass().addClass('right');
      $(`#circle${currentPos}`).removeClass('active');
      $(`#span${nextPost}`).removeClass().addClass('center');
      $(`#circle${nextPost}`).addClass('active');

      animationForSlider();
    });

    $('#nextYear').on('click', function (el) {
      $('#prevYear').removeClass('disabled');
      const currentPos = $('.center').attr('id').replace('span', '');
      const nextPost = parseInt(currentPos) + 1;

      if (currentPos == $('#preMain .item').length - 1) {
        return;
      }

      if (nextPost == $('#preMain .item').length - 1) {
        $(this).addClass('disabled');
      }

      $('.center').removeClass().addClass('left');
      $(`#circle${currentPos}`).removeClass('active');
      $(`#span${nextPost}`).removeClass().addClass('center');
      $(`#circle${nextPost}`).addClass('active');

      animationForSlider();
    });

    function animationForSlider() {
      animateTriangles();

      var left = 0;
      $('#mainCont-track span.left').each(function (index) {
        left += $(this).outerWidth();
      });

      $('#mainCont-track').css('transform', `translateX(-${left}px)`);
    }

    function selectDate(selector) {
      let $selector = '#' + selector;
      let $spanSelector = $selector.replace('circle', 'span');
      var current = $selector.replace('#circle', '');
      var prevCurrent = $('.center').attr('id').replace('span', '');

      $('.active').removeClass('active');
      $($selector).addClass('active');

      if (current > prevCurrent) {
        $('.center').removeClass('center').addClass('left');
        $($spanSelector).removeClass().addClass('center');
      } else {
        $('.center').removeClass('center').addClass('right');
        $($spanSelector).removeClass().addClass('center');
        $($spanSelector).removeClass('left');
      }

      for (let i = 0; i < parseInt(current); i++) {
        $('#span' + i)
          .removeClass()
          .addClass('left');
      }

      for (let i = $('#mainCont span').length; i > parseInt(current); i--) {
        $('#span' + i)
          .removeClass()
          .addClass('right');
      }

      animationForSlider();
    }

    // expand image
    var $btnExpand = $("[data-action='open-modal']");
    var $btnCloseModal = $("[data-action='close-modal'");
    var $header = $('header');

    $btnExpand.on('click', function (e) {
      const modalId = $(this).data('target-modal');
      var $modal = $(`#${modalId}`);
      $modal.addClass('is--active');
      $header.addClass('hide');
    });

    $btnCloseModal.on('click', function () {
      const modalId = $(this).data('target-modal');
      var $modal = $(`#${modalId}`);
      $modal.removeClass('is--active');
      $header.removeClass('hide');
    });
  };

  // move triangles
  function makeNewPosition() {
    // Get viewport dimensions (remove the dimension of the image)
    var h = $('#mainCont').height() - 265;
    var w = $('#mainCont').width() - 265;

    var nh = Math.floor(Math.random() * h);
    var nw = Math.floor(Math.random() * w);

    return {
      top: nh,
      left: nw
    };
  }

  function animateElement(element) {
    var newq = makeNewPosition();
    $(element).animate({ top: newq.top, left: newq.left }, 3000);
  }

  function animateTriangles() {
    $('[data-target="timeline-triangle"]').each(function (index, element) {
      animateElement(element);
    });
  }

  animateTriangles();
})(jQuery, app, _);
