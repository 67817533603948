(function ($, app, _) {
  'use strict';
  app.general = function () {
    //Modal
    $('.modal-outside').on('click', function (e) {
      $(this).parent().toggleClass('is--active');
    });
    $('.modal-close').on('click', function (e) {
      $(this).parent().parent().toggleClass('is--active');
    });

    //Dropdown with arrow
    $('.dropdown-with-arrow .dropdown-with-arrow-value').on(
      'click',
      function (e) {
        $(this).parent().toggleClass('is--active');
      }
    );

    $('.dropdown-with-arrow-list a').on('click', function (e) {
      var value = $(this).attr('data-value'),
        label = $(this).html();

      $(this)
        .closest('.dropdown-with-arrow')
        .find('.dropdown-with-arrow-value')
        .attr('data-value', value);
      $(this)
        .closest('.dropdown-with-arrow')
        .find('.dropdown-with-arrow-value')
        .html(label);
      $(this).closest('.dropdown-with-arrow').toggleClass('is--active');
    });

    $('body').on('click', function (e) {
      if (
        $('.dropdown-with-arrow').hasClass('is--active') &&
        !$(e.target).closest('.dropdown-with-arrow').length
      ) {
        $('.dropdown-with-arrow').removeClass('is--active');
      }
    });
    $(window).on('scroll', function(e){
      let top  = $(document).scrollTop()
      let view = window.innerHeight

      $('#totop').toggleClass('visible', top > view/2);
    })

    $('#totop').on('click', function(e){
      $('body,html').animate({ scrollTop: 0 }, 1000);
    })

    $(window).scroll();
  };
})(jQuery, app, _);
