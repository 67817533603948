(function ($, app, _) {
  'use strict';
  app.m25 = function () {
		let postPerPage = 20;
    $('#date_from_25').datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd.mm.yy'
    });
    $('#date_to_25').datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd.mm.yy'
    });

    //Dropdown with arrow
    $('.m25__actions__result_value').on('click', function (e) {
      $(this).parent().toggleClass('is--active');
    });

    $('.m25__filters').on('click', function (e) {
		if ($('.m25__filters_dropdown').hasClass('dropdown-is--active') && $(e.target).closest('.m25__filters_dropdown').length) {
			return;
		}
      	$('.m25__filters_dropdown').toggleClass('dropdown-is--active');
      	$('.m25__filters_dropdown').parent().toggleClass('is--active');
    });
	
	$('body').on('click', function (e) {
		if ($('.m25__filters_dropdown').hasClass('dropdown-is--active') && !$(e.target).closest('.m25__filters').length) {
	      	$('.m25__filters_dropdown').toggleClass('dropdown-is--active');
	      	$('.m25__filters_dropdown').parent().toggleClass('is--active');
		}
	});

    $('.m25__actions__result_select a').on('click', function (e) {
      $('.m25__actions__result_select a.is--active').removeClass('is--active');
      $(this).addClass('is--active');
      	var value = $(this).attr('data-value'),
        label = $(this).html();
				postPerPage = value;

      $(this)
        .closest('.m25__actions__results')
        .find('.m25__actions__result_value')
        .attr('data-value', value);
      $(this)
        .closest('.m25__actions__results')
        .find('.m25__actions__result_value h6')
        .html(label);

			$(this).closest('.m25__actions__results').toggleClass('is--active');

			page = 1;
			loadNews();
    });
		$('#cb-19-19').click(function(){
			if($(this).prop("checked") == false){
				$('input[name^="catNews"]').prop("checked", false);
				$('input[name^="date_period"]').prop("checked", false);
				cat = [];
				date_range = '';
				loadNews();
			}
		});
		$('#submitForm25').click(function(){
			$('.m25__filters_dropdown').toggleClass('dropdown-is--active');
			$('.m25__filters_dropdown').parent().toggleClass('is--active');
			$('#cb-19-19').attr('checked','checked');

			loadNews();
		});

    $('body').on('click', function (e) {
      if (
        $('.m25__actions__results').hasClass('is--active') &&
        !$(e.target).closest('.m25__actions__results').length
      ) {
        $('.m25__actions__results').removeClass('is--active');
      }
    });

    var $moreTags = $('.more-tags');
    var $popupTags = $('.popup-tags');

    $('body').on('click', '.more-tags', function (e) {
      e.preventDefault();
      var isActive = $(this)
        .parents('.news_box-thumb')
        .find('.popup-tags')
        .hasClass('is--active');
      $popupTags.removeClass('is--active');
      $moreTags.removeClass('is--active');
      if (!isActive) {
        $(this)
          .parents('.news_box-thumb')
          .find('.popup-tags')
          .addClass('is--active');
        $(this)
          .parents('.news_box-thumb')
          .find('.more-tags')
          .addClass('is--active');
      }
		});

			let page = 1;
			let isLoading = false;
			let cat = [];
			let institute = [];
			let date_period = 'alle';
			let date_range = '';
			let orderBy = null;
			const $postsHolder = $('.newsItemHolder');
	
			$('#orderBy25 a').on('click', function (e) {
				orderBy = $(this).attr('data-value') != '' ? $(this).attr('data-value') : null;
				loadNews()
			});
	
			$('input[name^="catNews"]').change(function(e) {
				e.preventDefault();
				cat = $('input[name^="catNews"]:checked').map((i, el) => `'${$(el).val()}'`).get();
			});
	
			$('input[name^="institute"]').change(function(e) {
				e.preventDefault();
				institute = $('input[name^="institute"]:checked').map((i, el) => `${$(el).val()}`).get();
			});
	
			$('input[name^="date_period"]').change(function(e) {
				e.preventDefault();
				date_period = $('input[name^="date_period"]:checked').val();
			});
	
			function showNews(posts, pagination, label) {
				let html = '';
				let paginationHtml = '';
				
				posts.forEach(function (post) {
					
					let categories = post.categories;
					let extraCat = [];
					let categoriesHtml = '';

					if(categories.length > 0){
						let catLength = categories.length;
						var homeUrl = rest_api_js_vars.wp_api_url.split('wp-admin')[0];

						if(catLength > 2){
							categories = categories.slice(0,2);
							extraCat = categories.slice(2,catLength);
						}
						categoriesHtml += '<ul class="tags-area">';
						categories.forEach((category) => {
								categoriesHtml += `<li><a href="${homeUrl}/news/?cat=${category.slug}">${category.name}</a></li>`
						});
						categoriesHtml += '</ul>';

	
						if(extraCat.length > 0){
							categoriesHtml += `<span class="more-tags"></span>
							<div class="popup-tags">
								<span>${label}</span>
							<ul class="tags-area">`;
							extraCat.forEach((categoryExtra) => {
								categoriesHtml +=  `<li><a href="${homeUrl}/news/?cat=${categoryExtra.slug}">${categoryExtra.name}</a></li>`
							});
							categoriesHtml += `</ul></div>`
						}
					}
					html += `
					<div class="column-6 column-mob-12">
						<div class="m25__item">
							<div class="news_box">
								<div class="news_box-thumb">
									<a `+(post.isCurrentBlog ? '' : 'target="_blank"')+` href="` +
										post.permalink +
										`">
													<img src="` +
										post.thumbnail +
										`" alt="` +
										post.title +
										`">
									</a>
								</div>
								<div class="news_box-disc_right">
										<div class="news_box-dsc">
											<a `+(post.isCurrentBlog ? '' : 'target="_blank"')+` href="` +
												post.permalink +
												`">
																	<h3>` +
												post.title +
												`</h3>
											</a>
											` + post.leading +
										`</div>
										<div class="news_box-right">
											<a class="link-green" `+(post.isCurrentBlog ? '' : 'target="_blank"')+` href="${post.permalink}">
												<p>`+ post.blogName +`</p>
											</a>
											<span>` +
												post.date +
											`</span>
										</div>
								</div>
							</div>
						</div>
					</div>
					`;
				});
	
				$postsHolder.html(html);
				
				$('#totalResult25').text(pagination.total);

				$('.nextPage-25').removeClass('hidden');
				$('.prevPage-25').removeClass('hidden');
				$('.pagination-25, .m25__footer_pagination').removeClass('hidden');
	
				if(pagination.last_page == pagination.current_page){
					$('.nextPage-25').addClass('hidden');
				}
	
				if(pagination.current_page == 1){
					$('.prevPage-25').addClass('hidden');
				}
				
				$('.nextPage-25').data('value', pagination.next_page);
				$('.prevPage-25').data('value', pagination.prev_page);
	
			if(pagination.last_page == 1){
					$('.pagination-25, .m25__footer_pagination').addClass('hidden');
				}

				for(let i=1; i <= pagination.last_page; i++){
					paginationHtml += `<option value="${i}" ${i == pagination.current_page ? 'selected' : ''}>Seite ${i}</option>`;
				}
	
				$('.m25__footer_pagination select').html(paginationHtml);
				$('.pagination-25 select').html(paginationHtml);
			}
	
			function loadNews() {
				if (isLoading) {
					return;
				}

				if(date_period == 'von'){
					date_range = `${$('#date_from_25').val()}-${$('#date_to_25').val()}`
				}else if(date_period == 'alle'){
					date_range = null;
				}else{
					var endDate = new Date();
					var startDate = new Date();
					startDate.setDate(endDate.getDate() - parseInt(date_period));
					date_range = `${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()}-${endDate.getDate()}.${endDate.getMonth() + 1}.${endDate.getFullYear()}`
				}

				isLoading = true;

				console.log(rest_api_js_vars.wp_api_url);
	
				$.ajax({
					type: 'GET',
					dataType: 'json',
					url: rest_api_js_vars.wp_api_url + '/news',
					data: {
						page,
						cat,
						institute,
						postPerPage,
						date_range,
						orderBy,
						lang: wpml_lang
					},
					success: function (data) {
						isLoading = false;
						showNews(data.data, data.pagination, data.label);
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) {
						isLoading = false;
					}
				});
			}

			$('.pagination-25, .m25__footer_pagination').on('click', 'a.nextPage-25, a.prevPage-25', function(e){
				e.preventDefault();
				page = $(this).data('value');
				loadNews();
			});

			$('.pagination-25 select, .m25__footer_pagination select').on('change', function(e){
				e.preventDefault();
				page = $(this).val();
				loadNews();
			});
		
  };
})(jQuery, app, _);
