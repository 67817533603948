
(function ($, app, _) {
  'use strict';
  app.swiper = function () {
    const swiper = new Swiper('.swiper', {
      loop: true,
      slidesPerView: 'auto',
      spaceBetween: 8,
      navigation: {
        nextEl: '.swiper-next',
        prevEl: '.swiper-prev',
      },
      on: {
        init: function (e) {
          updateSwiperIndex(e)
        },
        realIndexChange: function (e) {
          updateSwiperIndex(e)
        }
      }
    })

    function updateSwiperIndex(e) {
       setTimeout(() => {
        let description = $(e.el).find('.swiper-slide-active').attr('data-description')

        $(e.el).find('.swiper-index__current').text(e.realIndex + 1)
        $(e.el).find('.swiper-index__total').text(e.slides.length)
        $(e.el).find('.swiper-description').text(description)
      }, 100)
    }

  };
})(jQuery, app, _);