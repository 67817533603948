(function ($, app, _) {
  'use strict';
  app.m51 = function () {
    if( $('section.m51').length == 0 ) return;
    
    // init panzoom
    $( ".m51" ).each(function( index ) {
      let block_id = $(this).attr('data-block-id')

      let lastZoom = -1
      let elem     = document.getElementById('panzoom_' + block_id)
      let zoomIn   = document.getElementById('hotspots_zoom-in_' + block_id)
      let zoomOut  = document.getElementById('hotspots_zoom-out_' + block_id)
      let panzoom  = Panzoom(elem, {
        maxScale: 4,
        minScale: 1,
        contain: 'outside',
        // pinchAndPan: true,
        panOnlyWhenZoomed: true
      })

      zoomIn.addEventListener('click', panzoom.zoomIn)
      zoomOut.addEventListener('click', panzoom.zoomOut)

      elem.addEventListener('panzoomchange', (event) => {
        let scale     = event.detail.scale
        let transform = 1/scale
        $('.m51[data-block-id="' + block_id + '"] .hotspots .select-hotspot').css('transform', 'scale(' + transform + ')')
        
        console.log(block_id)
        console.log($('.m51[data-block-id="' + block_id + '"] .hotspots .select-hotspot'))
        
        // reset viewport
        if( lastZoom > scale && scale == 1 ) {
          panzoom.reset()
          lastZoom = -1
        }
        lastZoom = scale

      })
    });

    let current_id = -1;
    
    $('.m51 .select-hotspot').on('click', function(e) {
      let id = $(e.currentTarget).attr('data-id')
      let $hotspotDetails = $(e.currentTarget).closest('.m51').find('#hotspot_details')
      let $parent = $(e.currentTarget).closest('.m51')

      if( $hotspotDetails.hasClass('visible') && current_id == id ) {
        hideDetails( $parent );
      } else {
        showDetails( id, $parent );
      }
    });

    $('.m51 #hotspot_details, .m51 #hotspot_details .close').on('click', function(e) {
      let id = $(e.target).attr('id')
      if( id == 'hotspot_details' || id == 'hotspot_details_close' )  {
        let $parent = $(e.currentTarget).closest('.m51')
        hideDetails( $parent );
      }
    });

    $('.m51 .toggle-focus').on('click', function(e) {
      let $sidebar = $(e.currentTarget).closest('.m51').find('.sidebar')
      $sidebar.toggleClass('open')
    });

    function showDetails( id, $parent ) {
      let title = $parent.find('#' + id).attr('data-title')
      let desc  = $parent.find('#' + id).attr('data-desc')
      let url   = $parent.find('#' + id).attr('data-url')
      let label = $parent.find('#' + id).attr('data-label')
      
      // update content
      $parent.find('#hotspot_details_title').html(title)
      $parent.find('#hotspot_details_desc').html(desc)

      if( url && label ) {
        $parent.find('#hotspot_details_link').text(label)
        $parent.find('#hotspot_details_link').attr('href', url)
        $parent.find('#hotspot_details_link').show()
      } else {
        $parent.find('#hotspot_details_link').hide()
      }
      
      // remove classes
      $parent.find('.select-hotspot').removeClass('active')
      $parent.find('#hotspot_details').removeClass('intro')
      
      // set classes
      $parent.find('#hotspot_details').addClass('visible')
      $parent.find('.select-hotspot[data-id="' + id + '"]').addClass('active')

      current_id = id
    }

    function hideDetails( $parent ) {
      $parent.find('#hotspot_details').removeClass('visible intro')
      $parent.find('.select-hotspot[data-id="' + current_id + '"]').removeClass('active')
    }

  };
})(jQuery, app, _);
