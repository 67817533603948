(function ($, app, _) {
  'use strict';
  app.m11 = function () {
    var $loadMoreBtn = $('.loadMore');

    $loadMoreBtn.click(function (e) {
      e.preventDefault();
      $('.moreDownloads-' + $(this).data('post-id')).removeClass('hidden');
      $(this).parent().parent().addClass('hidden');
    });
  };
})(jQuery, app, _);
