/* add class on scroll */

(function ($, app, _) {
  'use strict';

  app.toggleHeaderWhenScrolling = function () {
    var doc = document.documentElement;
    var w = window;

    var prevScroll = w.scrollY || doc.scrollTop;
    var curScroll;
    var forwardScroll = 0;
    var backScroll = 0;
    var direction = 0;
    var prevDirection = 0;

    if ($('#header').length > 0) {
      var header = document.getElementById('header');
    }

    var checkScroll = function () {
      /*
       ** Find the direction of scroll
       ** 0 - initial, 1 - up, 2 - down
       */

      curScroll = w.scrollY || doc.scrollTop;

      if (curScroll > prevScroll) {
        forwardScroll += curScroll - prevScroll;
        if (forwardScroll < 300) return;
        //scrolled up
        direction = 2;
        forwardScroll = 0;
      } else if (curScroll < prevScroll) {
        backScroll = prevScroll - curScroll;
        if (backScroll < 300 && curScroll > 0) return;
        //scrolled down
        direction = 1;
        backScroll = 0;
      }

      if (direction !== prevDirection) {
        toggleHeader(direction, curScroll);
      }

      prevScroll = curScroll;
    };

    var toggleHeader = function (direction, curScroll) {
      if (direction === 2 && curScroll > 1) {
        //replace 52 with the height of your header in px
        if (header) {
          header.classList.add('hide');
          prevDirection = direction;
        }
      } else if (direction === 1) {
        if (header) {
          header.classList.remove('hide');
          prevDirection = direction;
        }
      }
    };
    window.addEventListener('scroll', checkScroll);
  };
})(jQuery, app, _);
