(function ($, app, _) {
  'use strict';
  app.m34 = function () {
    $('.teach-box-link-show-more').click(function () {
      var $showMoreBtn = $(this).parent().find('.sidebar-show-more-btn');
      var val = $(this).text();
      if (val == 'Weitere Lehrveranstaltungen anzeigen') {
        $(this).parent().parent().find('.cv-listing-hidden').css('display', 'block');
        $(this).text('Nur aktuelle anzeigen');
        $($showMoreBtn).toggleClass('sidebar-show-more-btn--rotate');
      } else {
        $(this).parent().parent().find('.cv-listing-hidden').css('display', 'none');
        $(this).text('Weitere Lehrveranstaltungen anzeigen');
        $($showMoreBtn).removeClass('sidebar-show-more-btn--rotate');
      }
      return false;
    });
  };
})(jQuery, app, _);
