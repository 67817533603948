(function ($, app, _) {
  'use strict';
  app.m41 = function () {
    if( $('section.m41').length == 0 ) return;

    let options = {
      valueNames: [ 
        { data: ['sorttitle'] },
        { data: ['searchtitle'] },
        { data: ['date'] },
      ]
    };

    let books        = new List('m41_books-listing', options);
    let choices_sort = new Choices($('select[name="ois-sort"]')[0], { itemSelectText: '', shouldSort: false, searchEnabled: false })

    books.sort('date', { 'order': 'desc' });
    updateCount();

    $('select[name="ois-sort"]').on('change', function() {
      let value = $(this).val()
      let order = ( value == 'date' ) ? 'desc' : 'asc'

      books.sort(value, { 'order': order });
      setTimeout( updateCount, 10 );
    });

    $('input[name="ois-search"]').on('keyup', function() {
      filterBooks();
      setTimeout( updateCount, 10 );
    });

    $('select[name^="ois-filter-"]').on('change', function() {
      filterBooks();
      setTimeout( updateCount, 10 );
    });

    $('.m41 .reset').on('click', function() {
      // reset fields
      $('input[name="ois-search"]').val('')
      choices_sort.setChoiceByValue('') 

      // reset filter
      books.filter();
      books.search();
      
      // reset sort
      books.sort('date', { 'order': 'desc' });

      // update counter
      setTimeout( updateCount, 10 );
    });

    $('.m41 .sidebar_toggle').on('click', function() {
      $('.sidebar').toggleClass('showForm');
    });

    function filterBooks() {
      let val_search = $('input[name="ois-search"]').val().toLowerCase()

      books.filter(function(item) { 
        if( item.values().searchtitle.toLowerCase().indexOf(val_search) == -1 ) return false
        
        return true
      }); 
    }

    function updateCount() {
      $('.no-items-found').toggle( books.visibleItems.length <= 0 )
      $('#m41_books-listing ul').toggle( books.visibleItems.length > 0  )
    }
  };
})(jQuery, app, _);
