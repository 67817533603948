(function ($, app, _) {
  'use strict';
  app.m15 = function () {
		let postPerPage = 10;
		let type = null;

    $('#date_from_15').datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd.mm.yy'
    });
    $('#date_to_15').datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd.mm.yy'
    });

		$('#submitForm15').click((e) =>{ 
			e.preventDefault();
			loadResults();
		})
    //Dropdown with arrow
    $('.m15__actions__result_value').on('click', function (e) {
      $(this).parent().toggleClass('is--active');
    });

    $('.m15__actions__result_select a').on('click', function (e) {
      $('.m15__actions__result_select a.is--active').removeClass('is--active');
      $(this).addClass('is--active');
      var value = $(this).attr('data-value'),
				label = $(this).html();
				postPerPage = value != 'alle' ? value.replace('1-', '') : 1000000;

      $(this)
        .closest('.m15__actions__results')
        .find('.m15__actions__result_value')
        .attr('data-value', value);
      $(this)
        .closest('.m15__actions__results')
        .find('.m15__actions__result_value h6')
        .html(label);

			$(this).closest('.m15__actions__results').toggleClass('is--active');
			loadResults();
    });

    $('body').on('click', function (e) {
      if (
        $('.m15__actions__results').hasClass('is--active') &&
        !$(e.target).closest('.m15__actions__results').length
      ) {
        $('.m15__actions__results').removeClass('is--active');
      }
		});
		
		let page = 1;
		let isLoading = false;
		let cat = [];
		let date_period = 'alle';
		let date_range = '';
		let orderBy = 'DESC';
		const $postsHolder = $('#searchResult15');

		$('#orderBy15 a').on('click', function (e) {
			orderBy = $(this).attr('data-value');
			loadResults()
		});

		$('#resultCategories15').on('change','input[name^="cat"]', function(e) {
			e.preventDefault();
			cat = $('input[name^="cat"]:checked').map((i, el) => `${$(el).val()}`).get();
			loadResults()
		});

		$('input[name^="date_period"]').change(function(e) {
			e.preventDefault();
			date_period = $('input[name^="date_period"]:checked').val();
			loadResults()
		});

		$('#postTypeHolder ul li a').click(function (el) {
			type = $(this).data('type') != 'alle' ? $(this).data('type') : null;
			loadResults();
		});

		const allHtmls = {
					
				teamHtml : (post, categoriesHtml) => {
					return `<div class="m15__item">
						<div class="team_box">
								<div class="team_box-thumb">
									<a href="${post.permalink}">
										<img src="${post.thumbnail}" alt="${post.title}">
									</a>
								</div>
								<div class="team_box-right-wrapper">
									<div class="team_box-dsc">
											<a href="${post.permalink}">
												<h3>${post.title}</h3>
											</a>
											<p>${post.blogName}</p>
											<p><a href="tel:${post.phone}">${post.phone}</a></p>
											<p><a href="mail:${post.email}">${post.email}</a></p>
									</div>
									<div class="team_box-right">
											${categoriesHtml}
									</div>
								</div>
						</div>
					</div>`;
				},

				booksHtml : (post, categoriesHtml) => {
					return `
					<div class="m15__item">
						<div class="news_box">
								<div class="news_box-thumb books_box-thumb">
									<a href="${post.permalink}">
										<img src="${post.cover}" alt="${post.title}">
									</a>
								</div>
								<div class="news_box-right-wrapper">
									<div class="news_box-dsc">
											<a href="${post.permalink}">
												<h3>${post.title}</h3>
											</a>
											<p>${post.subtitle}</p>
									</div>
									<div class="news_box-right">
											<a class="link-green" href="${post.permalink}">
												<p>${post.blogName}</p>
											</a>
									</div>
								</div>
						</div>
					</div>`;
				},

				newsHtml : (post, categoriesHtml) => {
					return `
					<div class="m15__item">
						<div class="news_box">
								<div class="news_box-thumb">
									<a href="${post.permalink}">
										<img src="${post.thumbnail}" alt="${post.title}">
									</a>
								</div>
								<div class="news_box-right-wrapper">
									<div class="news_box-dsc">
											<a href="${post.permalink}">
												<h3>${post.title}</h3>
											</a>
											${post.leading}
									</div>
									<div class="news_box-right">
											<a class="link-green" href="${post.permalink}">
												<p>${post.blogName}</p>
											</a>
											<span>${post.dateWithFullMonth}</span>
											${categoriesHtml}
									</div>
								</div>
						</div>
					</div>`;
				},

				publicationsHtml : (post, categoriesHtml) => {

					return `<div class="m15__item">
										<div class="publication_box">
												<div class="publication_box-dsc">
													<a href="${post.permalink}">
															<h3>${post.title}</h3>
															<span>${post.contributors}</span>
													</a>
												</div>
												<div class="publication_box-right">
													<a class="link-green" href="${post.permalink}">
															<p>${post.blogName}</p>
													</a>
													<span>${post.dateWithFullMonth}</span>
													${categoriesHtml}
												</div>
										</div>
									</div>`;
				},

				pageHtml : (post, categoriesHtml) => {
					return `<div class="m15__item">
										<div class="seite_box">
												<div class="seite_box-dsc">
													<a href="${post.permalink}">
															<h3>${post.title}</h3>
													</a>
												</div>
												<div class="seite_box-right">
													<a class="link-green" href="${post.permalink}">
															<p>${post.blogName}</p>
													</a>
													<ul class="tags-area" style="margin-top: 8px">
															<li><a href="${post.permalink}">${post.seiten}</a></li>
													</ul>
												</div>
										</div>
									</div>`;
				},

				eventsHtml : (post, categoriesHtml) => {

					let dateHtml = '';
					if(!post.sameDay) {
						dateHtml = `<span>${post.startDate['d']}.${post.startDate["M"]} ${post.startDate["Y"]} - ${post.endDate['d']}.${post.endDate["M"]} ${post.endDate["Y"]}</span>`;
					}
					
					return `<div class="m15__item">
										<div class="event_box">
												<div class="event_box-date">
													<a href="${post.permalink}">
															<h5>${post.startDate['d']}</h5>
															<h6>${post.startDate["M"]} ${post.startDate["y"]}</h6>
													</a>
												</div>
												<div class="event_box-right-wrapper">
													<div class="event_box-dsc">
															<a href="${post.permalink}">
																<h3>${post.title}</h3>
																${dateHtml}
															</a>
													</div>
													<div class="event_box-right">
															<a class="link-green" href="${post.permalink}">
																<p>${post.blogName}</p>
															</a>
															<span>${post.dateWithFullMonth}</span>
															${categoriesHtml}
													</div>
												</div>
										</div>
									</div>`;
				},

				downloadsHtml : (post, categoriesHtml) => {
					let fileHtml = '';

					if(post.subtype && post.size){
						fileHtml = `<div class="seite_box-right">
													<h5>${post.subtype}</h5>
													<h5>${post.size}</h5>
												</div>`
					}
					return `<div class="m15__item">
										<div class="seite_box">
												<div class="seite_box-dsc">
													<a href="${post.permalink}">
															<h3>${post.title}</h3>
															<p>${post.short_content}</p>
													</a>
												</div>
												${fileHtml}
										</div>
									</div>`;
				}
		}
		
		function showResult(data) {
			let html = '';
			let paginationHtml = '';
			const posts = data.data;
			const pagination = data.pagination;
			
			posts.forEach((post) => {
				let categories = post.categories;
				let categoriesHtml = '';
	
				if(Object.keys(categories).length > 0){
					categoriesHtml += '<ul class="tags-area">';
					Object.keys(categories).forEach((key) => {
							categoriesHtml += `<li><a href="${post.permalink}">${categories[key].name}</a></li>`
					});
					categoriesHtml += '</ul>';
				}
				html += allHtmls[`${post.type}Html`](post, categoriesHtml);
			});

			if(data.categories){
				let catHtml = '';
				
				Object.keys(data.categories).forEach((key) => {
					const category = data.categories[key];
					catHtml += `<div class="search_filter">
									<div class="search_filter__header">
											<h5>${category.name}</h5>
									</div>
									<div class="search_filter__main">
											<ul class="checkbox_btns">`
												Object.keys(category.children).forEach((child) => {
														const childSlug  = `'${category.children[child].slug}'`;
														catHtml += `<li>
																	<input type="checkbox" id="cb-3-${childSlug}" ${cat.includes(childSlug) ? 'checked' : ''} name="cat[]" value="${childSlug}">
																	<label for="cb-3-${childSlug}" class="label-checkbox">${category.children[child].name}</label>
															</li>`
												});
					catHtml += `</ul></div></div>`
				});
				$('#resultCategories15').html(catHtml);			
			}
			$postsHolder.html(html);
			
			$('#totalResult15').text(pagination.total);

			$('#postTypeHolder ul li a:gt(0)').map((i,el) => {
				const postType = $(el).data('type');
				const postTypeData = data.postTypeCount[postType];

				$(el).parent().removeClass('is--active');
				$(el).parent().removeClass('is--disabled');
				$(el).text(`${postTypeData.label} (${postTypeData.postTotal})`);

				if(postTypeData.isActive){
					$(el).parent().addClass('is--active')
				}

				if(!postTypeData.postTotal){
					$(el).parent().addClass('is--disabled')
				}
			});

			const firstPostTypeHolder = $('#postTypeHolder ul li a:first');
			firstPostTypeHolder.parent().removeClass('is--active').removeClass('is--disabled');
			
			if(type == null){
				firstPostTypeHolder.parent().addClass('is--active');
			}
			
			if(!data.allCounted){
				firstPostTypeHolder.parent().addClass('is--disabled');
			}

			firstPostTypeHolder.text(firstPostTypeHolder.text().trim().replace(/ *\([^)]*\) */g, ` (${data.allCounted})`))

			$('.nextPage-15').removeClass('hidden');
			$('.prevPage-15').removeClass('hidden');
			$('.pagination-15, .m15__footer_pagination').removeClass('hidden');

			if(pagination.last_page == pagination.current_page){
				$('.nextPage-15').addClass('hidden');
			}

			if(pagination.current_page == 1){
				$('.prevPage-15').addClass('hidden');
			}
			
			$('.nextPage-15').data('value', pagination.next_page);
			$('.prevPage-15').data('value', pagination.prev_page);

			const paginationNumbers = customPagination(pagination.last_page);

			if(paginationNumbers.length == 1){
				$('.pagination-15, .m15__footer_pagination').addClass('hidden');
			}

			for(let pageNr of paginationNumbers){
					paginationHtml += `<li><a href="javascript:;" class="paginationPage-15 ${pageNr == pagination.current_page ? 'is--active' : ''}" data-value="${pageNr}">${pageNr}</a></li>`;
			}

			$('.m15__footer_pagination ul').html(paginationHtml);
			$('.pagination-15 ul').html(paginationHtml);
		}

		function loadResults() {
			if (isLoading) {
				return;
			}

			if(date_period == 'von'){
				if($('#date_from_15').val() && $('#date_to_15').val()){
					date_range = `${$('#date_from_15').val()}-${$('#date_to_15').val()}`
				}else{
					date_range = null;
				}
			}else if(date_period == 'alle'){
				date_range = null;
			}else{
				var endDate = new Date();
				var startDate = new Date();
				startDate.setDate(endDate.getDate() - parseInt(date_period));
				date_range = `${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()}-${endDate.getDate()}.${endDate.getMonth() + 1}.${endDate.getFullYear()}`
			}

			isLoading = true;
			
			$.ajax({
				type: 'GET',
				dataType: 'json',
				url: rest_api_js_vars.wp_api_url + '/search',
				data: {
					page,
					cat,
					type,
					postPerPage,
					date_range,
		 			search: $('#search15').val(),
					orderBy
				},
				success: function (data) {
					isLoading = false;
					showResult(data);
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					isLoading = false;
				}
			});
		}

		$('.pagination-15, .m15__footer_pagination').on('click', 'a.nextPage-15, a.paginationPage-15, a.prevPage-15', function(e){
			e.preventDefault();
			page = $(this).data('value');
			loadResults();
		});

		function customPagination(lastPage, splitNumber = 9){
			let paginationNumbers = [...Array(lastPage + 1).keys()].slice(1);

			if(lastPage > splitNumber){
				let endPage = lastPage > (splitNumber + 3) ? splitNumber : lastPage -3;
				let beforeLastPage = lastPage - 1;
				paginationNumbers = [...Array(endPage + 1).keys()].slice(1).concat(['...', beforeLastPage, lastPage]);
			}
			
			return paginationNumbers;
		}
  };
})(jQuery, app, _);
