(function ($, app, _) {
  'use strict';
  app.m27 = function () {
    var $searchInput = $('.m27 input[name=search]');
    var qsRegex  = '';
    var qsRegexYear  = '';
    var $quicksearch  = '';
    var $year  = '';

    $('.m27 .dropdown-with-arrow-list-m27 a').on('click', function (e) {
      $year = $(this).attr('data-value');

      qsRegex = new RegExp( `${$quicksearch}`, 'gi' );
      qsRegexYear = new RegExp( `${$year}`, 'gi' );

      $grid.isotope();
    });
        
    // init Isotope
    var $grid = $('.m27 .itemDataHolder').isotope({
      itemSelector: '.m27 .item-list-download-blue',
      filter: function() {
        if(!qsRegex && !qsRegexYear){
          return true;
        }

        return $(this).text().match( qsRegex ) &&  $(this).text().match( qsRegexYear );
      }
    });

    // use value of search field to filter
    $searchInput.keyup( debounce( function() {
      $quicksearch = $(this).val();

      qsRegex = new RegExp( `${$quicksearch}`, 'gi' );
      qsRegexYear = new RegExp( `${$year}`, 'gi' );

      $grid.isotope();
    }, 200 ) );

    // debounce so filtering doesn't happen every millisecond
    function debounce( fn, threshold ) {
      var timeout;
      threshold = threshold || 100;
      return function debounced() {
        clearTimeout( timeout );
        var args = arguments;
        var _this = this;
        function delayed() {
          fn.apply( _this, args );
        }
        timeout = setTimeout( delayed, threshold );
      };
    }

  };
})(jQuery, app, _);
