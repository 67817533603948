(function ($, app, _) {
  'use strict';
  app.m46 = function () {
    var $toggle = $('.m46 .toggle');
    
    $toggle.on('click', function(e){
      e.preventDefault();
      $('.m46 .gallery').toggleClass('collapsed')
    });
  };
})(jQuery, app, _);
