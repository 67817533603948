(function ($, app, _) {
  'use strict';
  app.m10 = function () {
    var $btnDropdown = $('.m10 .btn-dropdown');
    var $dropdown = $('.m10 .dropdown');
    var $searchInput = $('.m10 #search-10');
    var $postsHolder = $('.m10 .teamDataHolder');
    var $dropdownItem = $('.m10 .team-profiles-content-header-dropdown-item');
    var isLoading = false;
    var orderBy = '';
    var search = '';
    var i = 0;

    $btnDropdown.click(function (e) {
      e.preventDefault();
      $dropdown.toggleClass('is--active');
      $btnDropdown.toggleClass('is--active-dropdown');
    });
    
    $dropdownItem.click(function (e) {
      e.preventDefault();
      $dropdownItem.toggleClass('item-is--active');
    });

    $searchInput.keyup(debounce(function(){
          // the following function will be executed every half second
          search = $(this).val();
          if(search.length > 1){
            loadTeam();
          }else{
            search = '';
            loadTeam();
          }
    },500));


    function loadTeam(){
      if (isLoading) {
        return;
			}
			
      const termIds = $('.m10 input[name="term_ids"]').val();
			isLoading = true;
			
      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: rest_api_js_vars.wp_api_url + '/team/all',
        data: {
          search,
          termIds,
          orderBy
        },
        success: function (data) {
          isLoading = false;
          showPosts(data);
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          isLoading = false;
        }
      });
    }

    function showPosts(posts) {

      var html = '';

      if(!posts){
        $postsHolder.html('');
        return;
      }

      Object.keys(posts).forEach(function (category) {

        html +=  `<div class="team-profiles-content-header" id="${slugify(category)}">
              <span class="team-profiles-content-header-title">${category}</span>
          </div><div class="row ">`;

        posts[category].forEach(function (post) {
          html +=`
            <div class="column-6 column-tab-12 column-mob-12">
              <div class="team-profiles-card">
                <div class="team-profiles-card-thumb">
                  <img src="` +
                  post.thumbnail +
                  `" alt="` +
                  post.title +
                  `"/>
                </div>
                <div class="team-profiles-card-desc">
                    <a href="` +
                        post.permalink +
                        `">
                        <h6>` +
                        post.title +
                        `</h6>
                    </a>
                    <a href="` +
                      post.permalink +
                      `">
                      <span class="team-profiles-card-str">` +
                      post.profession +
                      `</span>
                  </a>
                  <a href="tel:` +
                      post.telefon +
                      `">
                      <span class="team-profiles-card-tel">` +
                      post.telefon +
                      `</span>
                  </a>
                  <a href="mailto:` +
                    post.email +
                    `">
                    <span class="team-profiles-card-email">` +
                    post.email +
                    `</span>
                  </a>
                </div>
              </div>
            </div>
          `;
        });
        html += `</div>`
      });
      $postsHolder.html(html);
    }
    
    function debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    function slugify(text) {
      return text
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .normalize('NFD')       // The normalize() method returns the Unicode Normalization Form of a given string.
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    }
  };
})(jQuery, app, _);
