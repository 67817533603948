$(document).ready(function () {
  (function ($, app, _) {
    'use strict';
    app.m01 = function () {
      var $slickSlider = $('.m01 .banner-slider');

      $slickSlider
        .on('init', function (event, slick) {
          $(this).append(
            '<div class="slick-counter"><span class="current"></span>/<span class="total"></span></div>'
          );
          $('.m01 .current').text(slick.currentSlide + 1);
          $('.m01 .total').text(slick.slideCount);
        })

        .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
          $('.m01 .current').text(nextSlide + 1);
        });
      var time = 2;
      var $bar, $slickSlider, isPause, tick, percentTime;

      $slickSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: true,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              swipeToSlide: true,
              arrows: false,
              dots: false,
              infinite: false,
              variableWidth: true,
              centerMode: false,
              centerPadding: '0'
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              arrows: false,
              centerMode: true,
              infinite: true,
              variableWidth: true,
              centerPadding: '256px'
            }
          }
          // {
          //   breakpoint: 1240,
          //   settings: {
          //     slidesToShow: 2
          //   }
          // }
        ]
      });
      if ($(window).width() > 1023) {
        $bar = $('.slider-progress .progress');

        $('.slider-wrapper').on({
          mouseenter: function () {
            isPause = true;
          },
          mouseleave: function () {
            isPause = false;
          }
        });

        function startProgressbar() {
          resetProgressbar();
          percentTime = 0;
          isPause = false;
          tick = setInterval(interval, 30);
        }

        function interval() {
          if (isPause === false) {
            percentTime += 1 / (time + 0.1);
            $bar.css({
              width: percentTime + '%'
            });
            if (percentTime >= 100) {
              $slickSlider.slick('slickNext');
              startProgressbar();
            }
          }
        }

        function resetProgressbar() {
          $bar.css({
            width: 0 + '%'
          });
          clearTimeout(tick);
        }

        startProgressbar();
      }
    };
  })(jQuery, app, _);
});
