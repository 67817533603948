(function ($, app, _) {
  'use strict';
  app.m14 = function () {
    var $slickSliderPressphoto = $('.m14 .pressphoto_slider');

    $slickSliderPressphoto
      .on('init', function (event, slick) {
        $(this).append(
          '<div class="slick-counter-publication"><span class="current-publication"></span>/<span class="total-publication"></span></div>'
        );
        $('.m14 .current-publication').text(slick.currentSlide + 1);
        $('.m14 .total-publication').text(
          slick.slideCount <= 6 ? 1 : slick.slideCount
        );
      })
      .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.m14 .current-publication').text(nextSlide + 1);
      });

    $slickSliderPressphoto.slick({
      slidesToShow: 6,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: true,
      dots: false,
      infinite: false,
      variableWidth: true,
      centerMode: true,
      centerPadding: '0',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: true,
            centerMode: false,
            centerPadding: '65px'
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            arrows: true,
            centerMode: false,
            infinite: false,
            centerPadding: '260px'
          }
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
  };
})(jQuery, app, _);
