(function ($, app, _) {
  'use strict';
  app.m05 = function () {
    var $slickSliderPublication = $('.m05 .publication-slider');
    var $moduleHolder = $(".m05");
    var $loadMoreBtn = $moduleHolder.find(".load-more");
    var isLoading = false;
    var current_page = 1;
    var has_more_pages = true;
    var posts_per_page = $moduleHolder.attr("data-posts-per-page") ?? 5;
    var $postsHolder = $moduleHolder.find(".mobile-item-holder");

    $slickSliderPublication
      .on('init', function (event, slick) {
        $(this).append(
          '<div class="slick-counter-publication"><span class="current-publication"></span>/<span class="total-publication"></span></div>'
        );
        $('.m05 .current-publication').text(slick.currentSlide + 1);
        $('.m05 .total-publication').text(slick.slideCount);
      })

      .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.m05 .current-publication').text(nextSlide + 1);
      });

    $slickSliderPublication.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: false,
            arrows: true,
            centerMode: true,
            centerPadding: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            dots: false,
            arrows: true,
            centerMode: true,
            centerPadding: false
          }
        }
        // {
        //   breakpoint: 1240,
        //   settings: {
        //     slidesToShow: 2
        //   }
        // }
      ]
    });

    $loadMoreBtn.on("click", function (e) {
      e.preventDefault();
      fetchMorePosts();
    });
  
    function fetchMorePosts() {
      current_page += 1;
      submitForm();
    }
  
  
    function showPosts(posts, append) {
      if (!append) {
        append = false;
      }
      var html = "";
  
      posts.forEach(function (post) {
  
        let categoriesHtml = '<ul class="tags-area">';
        if(post.categories.length > 0){
        	post.categories.forEach(category => {
        		categoriesHtml += `<li><a href="">${category.name}</a></li>`
        	});
        }
        categoriesHtml += '</ul>'
				let thumbHtml = '';

				if(post.post_type == 'books'){
					thumbHtml = `<div class="publication-slider-item-left--thumb">
														<img src="`+ post.thumbnail +`">
												</div>`;
				}

        html += `
          <div class="column-12 publication-slider-item-inner">
							<div class="column-6 column-mob-12 publication-slider-item-left">
							 `+ thumbHtml +`
								<div class="`+ post.post_type == 'books' ? 'publication-slider-item-left--content' : '' +`">
                  <a class="title" `+(post.isCurrentBlog ? '' : 'target="_blank"')+` href="` + post.permalink + `">
                      <h3>` + post.title + `</h3>
									</a>
								</div>
              </div>
              <div class="column-6 column-mob-12 publication-slider-item-right">
                  ` + post.contributors + `
                  <a class="link-green" `+(post.isCurrentBlog ? '' : 'target="_blank"')+` href="` + post.permalink + `">
                    <p>`+ post.blogName +`</p>
                  </a>
                  <p>` + post.date + `</p>
                 ${categoriesHtml}
              </div>
          </div>
          `;
      });
  
      if (append) {
        $postsHolder.append(html);
      } else {
        $postsHolder.html(html);
      }
    }
  
      function submitForm() {
        if (isLoading) {
          return;
        }
    
        if (!has_more_pages) {
          return;
        }
    
        isLoading = true;
    
        $.ajax({
          type: "GET",
          dataType: "json",
          url: rest_api_js_vars.wp_api_url + "/publications",
          data: {
            page: current_page,
            posts_per_page: posts_per_page,
          },
          success: function (data) {
            isLoading = false;
            onPostFetch(data.data, data.has_more_pages);
          },
          error: function (XMLHttpRequest, textStatus, errorThrown) {
            isLoading = false;
          },
        });
      }
  
      function onPostFetch(posts, has_more) {
          showPosts(posts, current_page > 1);
    
          has_more_pages = has_more;
    
          if (!has_more_pages) {
            $loadMoreBtn.hide();
          } else {
            $loadMoreBtn.show();
          }
      }
  };
})(jQuery, app, _);
