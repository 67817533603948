(function ($, app, _) {
  'use strict';
  app.m12b = function () {
    var $btn = $('.m12b__btn');
    var $modal = $('.modal-m12b');
    var $searchInput = $('input[name=search]');
    var qsRegex  = '';
    var qsRegexYear  = '';
    var $quicksearch  = '';
    var $year  = '';

    $btn.click(function (e) {
      e.preventDefault();
      $modal.toggleClass('is--active');
    });
            
    // init Isotope
    var $grid = $('.realesesModal').isotope({
      itemSelector: '.releases-list__item',
      filter: function() {
        if(!qsRegex && !qsRegexYear){
          return true;
        }

        return $(this).text().match( qsRegex ) && $(this).text().match( qsRegexYear );
      }
    });

    $('.dropdown-with-arrow-list-m12b a').on('click', function (e) {
      $year = $(this).attr('data-value');

      qsRegex = new RegExp( `${$quicksearch}`, 'gi' );
      qsRegexYear = new RegExp( `${$year}`, 'gi' );

      $grid.isotope();
    });


    // use value of search field to filter
    $searchInput.keyup( debounce( function() {
      $quicksearch = $(this).val();

      qsRegex = new RegExp( `${$quicksearch}`, 'gi' );
      qsRegexYear = new RegExp( `${$year}`, 'gi' );

      $grid.isotope();
    }, 200 ) );

    // debounce so filtering doesn't happen every millisecond
    function debounce( fn, threshold ) {
      var timeout;
      threshold = threshold || 100;
      return function debounced() {
        clearTimeout( timeout );
        var args = arguments;
        var _this = this;
        function delayed() {
          fn.apply( _this, args );
        }
        timeout = setTimeout( delayed, threshold );
      };
    }

  };
})(jQuery, app, _);
