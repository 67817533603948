(function ($, app, _) {
  'use strict';
  app.m18 = function () {
    var $accordionTitles = $('.small_accordions__title');
    var $searchFilter = $('#institute_filter');

    $accordionTitles.click(function (e) {
      e.preventDefault();

      var $accordionItem = $(this).parent().get(0);

      $accordionItem.classList.toggle('small_accordions__item--toggled');
    });

    $searchFilter.on('input', function (e) {
      e.preventDefault();

      var inputValue = e.target.value.toLowerCase();

      if (inputValue.length < 2) {
        $('.small_accordions__item.hidden').each(function (index, item) {
          item.classList.remove('hidden');
        });
        return;
      }

      $accordionTitles.each(function () {
        var $parent = $(this).parent().get(0);
        var title = $(this).text().toLowerCase();
        var includesText = title.indexOf(inputValue) !== -1;

        includesText
          ? $parent.classList.remove('hidden')
          : $parent.classList.add('hidden');
      });
    });
  };
})(jQuery, app, _);
