(function ($, app, _) {
  'use strict';
  app.m04 = function () {
    var $slickSliderEvents = $('.m04 .events-slider');

    $slickSliderEvents.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: true,
      dots: false,
      infinite: true,
      variableWidth: true,
      centerMode: true,
      centerPadding: '0',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: true,
            centerMode: false,
            centerPadding: '65px'
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            arrows: true,
            centerMode: false,
            infinite: false,
            centerPadding: '260px'
          }
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });

    var maxHeight = -1;
    $('.m04 .slick-slide').each(function () {
      if ($(this).outerHeight() > maxHeight) {
        maxHeight = $(this).outerHeight();
      }
    });
    $('.m04 .slick-slide').each(function () {
      if ($(this).outerHeight() < maxHeight) {
        $(this).css('min-height', maxHeight);
      }
    });
  };
})(jQuery, app, _);
