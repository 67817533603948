(function ($, app, _) {
  'use strict';
  app.m29 = function () {
    let page = 1;
    let isLoading = false;
    let cat = [];
    let institute = [];
    var $searchInput = $('#search-29');
    let search = '';
    let orderBy = 'ASC';
    const $postsHolder = $('.jobs-listing-content-items');

    $('.loadMoreCat').on('click', function () {
      const stateOfLoad = $(this).data('value');
      $(this).parent().prev('ul').find('.sidebar-show-more').each((i,el) => stateOfLoad === 'showLess' ? $(el).removeClass('hidden') : $(el).addClass('hidden'));
      
      //change text of show more element just for demonstration purposes to this demo
      if (stateOfLoad === 'showLess') {
        $(this).text($(this).data('show-less'));
        $(this).next().toggleClass('sidebar-show-more-btn--rotate');
        $(this).data('value','showMore');
      } else {
        $(this).text($(this).data('show-more'));
        $(this).next().removeClass('sidebar-show-more-btn--rotate');
        $(this).data('value','showLess');
      }
    });

    $('#orderBy29 a').on('click', function (e) {
      orderBy = $(this).attr('data-value');
      loadJobs()
    });


    $('input[name^="catJobs"]').change(function(e) {
      e.preventDefault();
      cat = $('input[name^="catJobs"]:checked').map((i, el) => parseInt($(el).val())).get();
      loadJobs();
    });

    $('input[name^="institute"]').change(function(e) {
      e.preventDefault();
      institute = $('input[name^="institute"]:checked').map((i, el) => parseInt($(el).val())).get();
      loadJobs();
    });

    function showPosts(posts, pagination) {
      let html = '';
			let paginationHtml = '';
			
      posts.forEach(function (post) {
				const targetBlank = post.pdf ? `target="_blank"` : '';
				const pdfUrl = post.pdf ? post.pdf : 'javascript:;';

        html +=
          `
					<div class="row">
            <div class="column-12">
              <div class="jlci-padding">
                <div class="row">
                  <div class="column-6 column-tab-7 column-mob-12">
									<a href="` + pdfUrl +`" ${targetBlank}><span class="jobs-listing-content-item-title">` + post.title + `</span></a>
                    <div class="jobs-listing-content-item">
                      <span class="jobs-listing-content-item--time">` + post.daysBeforeWithText +`</span>
                      <span class="jobs-listing-content-item--majority">`+ post.job +`</span>
                    </div>
                  </div>
                  <div class="jlcic-style column-6 hidden-tab mobile-hidden">
                      <div class="column-6">
                        <span>`+ post.organisation +`</span>
                      </div>
                      <div class="column-3">
                        <span>`+ post.ort +`</span>
                      </div>
                      <div class="jobs-listing-content-header--padding column-3">
                        <span>`+ post.applicationTime +`</span>
                      </div>
                  </div>
                  <div class="jlcic-style jlcic-style-mobile column-5 column-mob-12 desktop-hidden hidden-tablet-big">
                    <span>` + post.title + `</span>
                    <div class="jlcic-style-mobile-item">
                      <span>`+ post.ortLabel +`: </span>
                      <span>`+ post.ort +`</span>
                    </div>
                    <div class="jlcic-style-mobile-item">
                      <span>`+ post.applicationTimeLabel +`: </span>
                      <span>`+ post.applicationTime +`</span>
                    </div>
                  </div>
                </div>
							</div>
						</div>
					</div>
				`;
      });

			$postsHolder.html(html);
			
			$('.nextPage-29').removeClass('hidden');
			$('.backPage-29').removeClass('hidden');
			$('.pagination-29, .m29__footer_pagination').removeClass('hidden');

			if(pagination.last_page == pagination.current_page){
				$('.nextPage-29').addClass('hidden');
			}

			if(pagination.current_page == 1){
				$('.backPage-29').addClass('hidden');
			}
			
			$('.nextPage-29').data('value', pagination.next_page);
			$('.backPage-29').data('value', pagination.prev_page);

			const paginationNumbers = customPagination(pagination.last_page);

			if(paginationNumbers.length == 1){
				$('.pagination-29, .m29__footer_pagination').addClass('hidden');
			}

			for(let pageNr of paginationNumbers){
					paginationHtml += `<span class="paginationPage-29 ${pageNr == pagination.current_page ? 'currentPage' : ''}" data-value="${pageNr}">${pageNr}</span>`;
			}

			$('.jobs-listing-content-items--numbers').html(paginationHtml)
    }

    function loadJobs() {
      if (isLoading) {
        return;
      }

      isLoading = true;

      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: rest_api_js_vars.wp_api_url + '/jobs',
        data: {
          page,
          cat,
          institute,
          search,
          orderBy
        },
        success: function (data) {
          isLoading = false;
          showPosts(data.data,data.pagination);
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          isLoading = false;
        }
      });
    }

    $searchInput.keyup(debounce(function(){
      search = $(this).val();
      if(search.length > 3){
        loadJobs();
      }else{
        search = '';
        loadJobs();
      }
    },500));

    function debounce(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        var later = function() {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
		};

		$('html').on('click', '.nextPage-29, .backPage-29', function(e){
			e.preventDefault();
			page = $(this).attr('data-value');
			loadJobs();
		});

		$('.jobs-listing-content-items--numbers').on('click', '.paginationPage-29', function(e){
			e.preventDefault();
			page = $(this).attr('data-value');
			loadJobs();
		});

		function customPagination(lastPage, splitNumber = 9){
			let paginationNumbers = [...Array(lastPage + 1).keys()].slice(1);

			if(lastPage > splitNumber){
				let endPage = lastPage > (splitNumber + 3) ? splitNumber : lastPage -3;
				let beforeLastPage = lastPage - 1;
				paginationNumbers = [...Array(endPage + 1).keys()].slice(1).concat(['...', beforeLastPage, lastPage]);
			}
			
			return paginationNumbers;
		}
	
  };
})(jQuery, app, _);
