(function ($, app, _) {
  'use strict';
  app.m16 = function () {
    var $slickSliderTeamPreview = $('.m16 .team-preview-slider');

    $slickSliderTeamPreview
      .on('init', function (event, slick) {
        $(this).append(
          '<div class="slick-counter-publication"><span class="current-publication"></span>/<span class="total-publication">'+slick.slideCount+'</span></div>'
        );
        $('.m16 .current-publication').text(slick.currentSlide + 1);

      })
      .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.current-publication').text(nextSlide + 1);
      });

    $slickSliderTeamPreview.slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: true,
      dots: false,
      infinite: false,
      variableWidth: true,
      centerMode: true,
      centerPadding: '0',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            arrows: true,
            centerMode: false,
            centerPadding: '65px'
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            arrows: true,
            centerMode: false,
            infinite: false,
            centerPadding: '260px'
          }
        },
        {
          breakpoint: 1240,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });

    var maxHeight = -1;
    $('.m16 .slick-slide').each(function () {
      if ($(this).outerHeight() > maxHeight) {
        maxHeight = $(this).outerHeight();
      }
    });
    $('.m16 .slick-slide').each(function () {
      if ($(this).outerHeight() < maxHeight) {
        $(this).css('min-height', maxHeight);
      }
    });
  };
})(jQuery, app, _);
