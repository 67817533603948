(function ($, app, _) {
  'use strict';
  app.m20 = function () {
    var $accordionItems = $('.big_accordions__item');

    $accordionItems.click(function (e) {
      $(this).get(0).classList.toggle('big_accordions__item--toggled');
    });
  };
})(jQuery, app, _);
