(function ($, app, _) {
  'use strict';
  app.m03 = function () {
    var $moduleHolder = $('.m03.v03');
    var $slickSliderNews = $('.m03.v03 .news-slider');
    var $loadMoreBtn = $moduleHolder.find('.load-more');
    var isLoading = false;
    var current_page = 1;
    var has_more_pages = true;
    var posts_per_page = $moduleHolder.attr('data-posts-per-page') ?? 6;
    var $postsHolder = $moduleHolder.find('.mobile-item-holder>ul');

    $('body').click(function (e) {
      if (!$(e.target).is('.more-tags') && !$(e.target).is('.popup-tags')) {
        $('.popup-tags').removeClass('is--active');
      }
    });

    $slickSliderNews
      .on('init', function (event, slick) {
        $(this).append(
          '<div class="slick-counter-news"><span class="current-news"></span>/<span class="total-news"></span></div>'
        );
        $('.m03.v03 .current-news').text(slick.currentSlide + 1);
        $('.m03.v03 .total-news').text(slick.slideCount);
      })

      .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.m03.v03 .current-news').text(nextSlide + 1);
      });

    var $moreTags = $('.more-tags');
    var $popupTags = $('.popup-tags');

    $moreTags.click(function (e) {
      e.preventDefault();
      var isActive = $(this)
        .parents('.box-thumb')
        .find('.popup-tags')
        .hasClass('is--active');
      $popupTags.removeClass('is--active');
      $moreTags.removeClass('is--active');
      if (!isActive) {
        $(this)
          .parents('.box-thumb')
          .find('.popup-tags')
          .addClass('is--active');
        $(this).parents('.box-thumb').find('.more-tags').addClass('is--active');
      }
    });

    $slickSliderNews.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: false,
      infinite: false,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: false,
            arrows: true,
            centerMode: false,
            centerPadding: false
            // settings: 'unslick'
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            dots: false,
            arrows: true,
            centerMode: true,
            centerPadding: false

            // centerPadding: '140px'
          }
        }
        // {
        //   breakpoint: 1240,
        //   settings: {
        //     slidesToShow: 2
        //   }
        // }
      ]
    });

    $loadMoreBtn.on('click', function (e) {
      e.preventDefault();
      fetchMorePosts();
    });

    function fetchMorePosts() {
      current_page += 1;
      submitForm();
    }

    function showPosts(posts, append, label) {
      if (!append) {
        append = false;
      }
      var html = '';

      posts.forEach(function (post) {
        html +=
          `
					<li>
						<div class="box">
							<div class="box-thumb">
								<a href="` +
          post.permalink +
          `">
								<img src="` +
          post.thumbnail +
          `" alt="` +
          post.title +
          `">
								</a>
							</div>
							<div class="box-dsc">
									<a href="` +
          post.permalink +
          `">
										<h3>` +
          post.title +
          `</h3>
									</a>
									<div class="box-dsc-bottom">
										<a class="link-green" href="">
											<p>`+ post.blogName +`</p>
										</a>
										<span>` +
          post.date +
          `</span>
									</div>
							</div>
						</div>
					</li>
				`;
      });

      if (append) {
        $postsHolder.append(html);
      } else {
        $postsHolder.html(html);
      }
    }

    function submitForm() {
      if (isLoading) {
        return;
      }

      if (!has_more_pages) {
        return;
      }

      isLoading = true;

      $.ajax({
        type: 'GET',
        dataType: 'json',
        url: rest_api_js_vars.wp_api_url + '/news',
        data: {
          page: current_page,
          postPerPage: posts_per_page
        },
        success: function (data) {
          isLoading = false;
          onPostFetch(data.data, data.has_more_pages, data.label);
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
          isLoading = false;
        }
      });
    }

    function onPostFetch(posts, has_more, label) {
      showPosts(posts, current_page > 1, label);

      has_more_pages = has_more;

      if (!has_more_pages) {
        $loadMoreBtn.hide();
      } else {
        $loadMoreBtn.show();
      }
    }
  };
})(jQuery, app, _);
