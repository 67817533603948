(function ($, app, _) {
  'use strict';
  app.m30 = function () {
    var $loadMoreBtn = $('.loadMore');

    $loadMoreBtn.click(function (e) {
      e.preventDefault();
      $('.moreBoxes').removeClass('hidden');
      $(this).parent().parent().addClass('hidden');
    });
  };
})(jQuery, app, _);
