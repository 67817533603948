function acceptCookie( all ) {
  let cookies = ( getCookie('cookieconsent_2023') == null ) ? {} : JSON.parse( getCookie('cookieconsent_2023') );
  
  cookies.basic       = ( all ) ? true : document.querySelector('input[name="c_funktion"]').checked
  cookies.analytics   = ( all ) ? true : document.querySelector('input[name="c_statistics"]').checked
  cookies.socialmedia = ( all ) ? true : document.querySelector('input[name="c_marketing"]').checked

  if( cookies.analytics ) embed_matomo();

  document.cookie = "cookieconsent_2023=" + JSON.stringify(cookies) + "; path=/; max-age=" + (60 * 60 * 24 * 30);
  setTimeout(removeCookieBanner, 300);
}

if( getCookie('cookieconsent_2023') == null ) {
  setTimeout(showCookieBanner, 300);
} else {
  removeCookieBanner();
}

function removeCookieBanner() {
  document.getElementById("cookie-notice").remove();
}

function showCookieBanner() {
  document.getElementById("cookie-notice").style.opacity = "1"
}

function acceptSingleCookie( cookie ) {
  let cookies = ( getCookie('cookieconsent_2023') == null ) ? {} : JSON.parse( getCookie('cookieconsent_2023') );
  cookies[cookie] = true;
  document.cookie = "cookieconsent_2023=" + JSON.stringify(cookies) + "; path=/; max-age=" + (60 * 60 * 24 * 30);
}

function embed_matomo() {
  var script = document.createElement("script");
  script.type="text/javascript";
  script.innerHTML="var _paq = window._paq = window._paq || [];_paq.push(['trackPageView']);_paq.push(['enableLinkTracking']);(function() {var u=\"//matomo.lbg.ac.at/\";_paq.push(['setTrackerUrl', u+'matomo.php']);_paq.push(['setSiteId', " + match_matomo_id[current_blog_id] + "]);var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);})();";
  document.getElementsByTagName('head')[0].appendChild(script);
}

$(document).on("click", "#consent-display",function(e) {
  let cookie   = $(this).attr('data-cookie');
  let $parents = $('#cookie-request.' + cookie);

  // update cookies
  acceptSingleCookie( $(this).attr('data-cookie') );

  // display content on whole page
  $parents.each( function() {
    let html = $(this).attr('data-html');
    $(this).removeAttr('class').html(html);
  })
});

function getCookie(name) {
    var cookieArr = document.cookie.split(";");
    
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        if(name == cookiePair[0].trim()) {
          return decodeURIComponent(cookiePair[1]);
        }
    }
    
    return null;
}