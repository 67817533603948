(function ($, app, _) {
  'use strict';
  app.m26 = function () {
		let postPerPage = 20;
		let page = 1;
		let isLoading = false;
		let cat = [];
		let institute = [];
		let date_period = 'alle';
		let date_range = '';
		let year = 'alle';
		const $postsHolder = $('.eventsItemHolder');

		function showEvents(posts, pagination, label) {
			let html = '';
			let paginationHtml = '';
			
			posts.forEach(function (post) {
				let categories = post.categories;
				let categoriesHtml = '';

				if(Object.keys(categories).length > 0){
					categoriesHtml += '<ul class="tags-area">';
					Object.keys(categories).forEach((key) => {
							categoriesHtml += `<li><a href="">${categories[key].name}</a></li>`
					});
					categoriesHtml += '</ul>';
				}

				const endDateFormated = ` – ${post.formatedEndDate.day}. ${post.formatedEndDate.month} ${post.formatedEndDate.year}`

				let dateHtml = '';
				if(post.sameDay){
					dateHtml = `<p class="time">${post.displayStartTime} – ${post.displayEndTime} CET</p>`;
				} else {
					dateHtml = `<p class="time">${post.formatedStartDate.day}. ${post.formatedStartDate.month} ${post.formatedStartDate.year} ${endDateFormated}</p>`;
				}
				
				let locationHtml = '';
				if(post.location){
					locationHtml = `<p class="location">${post.location}</p>`;
				}

				html += `
				<div class="column-12">
					<div class="m26__item">
						<div class="news_box">
							<div class="news_box-thumb">
								<h2>${post.formatedStartDate.day}</h2>
										<label>
											${post.formatedStartDate.month} ${post.formatedStartDate.y}
									 </label>
								</a>
							</div>
							<div class="news_box_left">
									<div class="news_box-dsc">
										<a `+(post.isCurrentBlog ? '' : 'target="_blank"')+` href="${post.permalink}">
											<h3>${post.title}</h3>
											${dateHtml}
											${locationHtml}
										</a>
										` + post.leading +
									`</div>
									<div class="news_box-right">
										<a class="link-green" `+(post.isCurrentBlog ? '' : 'target="_blank"')+` href="${post.permalink}">
											<p>${post.blogName}</p>
										</a>
										${categoriesHtml}
									</div>
							</div>
						</div>
					</div>
				</div>
				`;
			});

			$postsHolder.html(html);
			
			$('#totalResult26').text(pagination.total);
			$('.nextPage-26').removeClass('hidden');
			$('.prevPage-26').removeClass('hidden');
			$('.pagination-26, .m26__footer_pagination').removeClass('hidden');

			if(pagination.last_page == pagination.current_page){
				$('.nextPage-26').addClass('hidden');
			}

			if(pagination.current_page == 1){
				$('.prevPage-26').addClass('hidden');
			}
			
			$('.nextPage-26').data('value', pagination.next_page);
			$('.prevPage-26').data('value', pagination.prev_page);

			if(pagination.last_page == 1){
				$('.pagination-26, .m26__footer_pagination').addClass('hidden');
			}

			for(let i=1; i <= pagination.last_page; i++){
				paginationHtml += `<option value="${i}" ${i == pagination.current_page ? 'selected' : ''}>Seite ${i}</option>`;
			}

			$('.m26__footer_pagination select').html(paginationHtml);
			$('.pagination-26 select').html(paginationHtml);
		}

		function loadEvents() {
			if (isLoading) {
				return;
			}
			const newold = $('input[name^="newold"]').val();
			if(date_period == 'von'){
				date_range = `${$('#date_from_26').val()}-${$('#date_to_26').val()}`
			}else if(date_period == 'alle'){
				date_range = null;
			}else{
				var endDate = new Date();
				var startDate = new Date();
				startDate.setDate(endDate.getDate() - parseInt(date_period));
				date_range = `${startDate.getDate()}.${startDate.getMonth() + 1}.${startDate.getFullYear()}-${endDate.getDate()}.${endDate.getMonth() + 1}.${endDate.getFullYear()}`
			}

			isLoading = true;
			
			if( Array.isArray(cat) ) cat = cat.join(',')

			$.ajax({
				type: 'GET',
				dataType: 'json',
				url: rest_api_js_vars.wp_api_url + '/events',
				data: {
					page,
					cat,
					institute,
					newold, 
					postPerPage,
					date_range,
					year
				},
				success: function (data) {
					isLoading = false;
					showEvents(data.data, data.pagination, data.label);
				},
				error: function (XMLHttpRequest, textStatus, errorThrown) {
					isLoading = false;
				}
			});
		}
		
		let currentPage = parseInt( $('.pagination-26 select').val() )
		if( currentPage > 1 ) {
			page = currentPage
			loadEvents()
		}

    $('#date_from_26').datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd.mm.yy'
    });
    $('#date_to_26').datepicker({
      changeMonth: true,
      changeYear: true,
      dateFormat: 'dd.mm.yy'
    });

    //Dropdown with arrow
    $('.m26__actions__result_value').on('click', function (e) {
      $(this).parent().toggleClass('is--active');
    });

	$('.m26__filters').on('click', function (e) {
		if ($('.m26__filters_dropdown').hasClass('dropdown-is--active') && $(e.target).closest('.m26__filters_dropdown').length) {
			return;
		}
      	$('.m26__filters_dropdown').toggleClass('dropdown-is--active');
      	$('.m26__filters_dropdown').parent().toggleClass('is--active');
    });
	
	$('.m26__sortieren').on('click', function (e) {
		if ($('.m26__filters_dropdown').hasClass('dropdown-is--active') && $(e.target).closest('.m26__filters_dropdown').length) {
			return;
		}
      	$('.m26__filters_dropdown').toggleClass('dropdown-is--active');
      	$('.m26__filters_dropdown').parent().toggleClass('is--active');
    });
	
	$('body').on('click', function (e) {
		if ($('.m26__filters_dropdown').hasClass('dropdown-is--active') && !$(e.target).closest('.m26__filters').length) {
	      	$('.m26__filters_dropdown').toggleClass('dropdown-is--active');
	      	$('.m26__filters_dropdown').parent().toggleClass('is--active');
		}
	});

    $('.m26__actions__result_select a').on('click', function (e) {
      $('.m26__actions__result_select a.is--active').removeClass('is--active');
      $(this).addClass('is--active');
      var value = $(this).attr('data-value'),
        label = $(this).html();
				postPerPage = value;

      $(this)
        .closest('.m26__actions__results')
        .find('.m26__actions__result_value')
        .attr('data-value', value);
      $(this)
        .closest('.m26__actions__results')
        .find('.m26__actions__result_value h6')
        .html(label);

			$(this).closest('.m26__actions__results').toggleClass('is--active');

			page = 1;
			loadEvents();
    });

		$('#cb-19-19').click(function(){
            if($(this).prop("checked") == false){
				$('input[name^="catEvents"]').prop("checked", false);
				$('input[name^="date_period"]').prop("checked", false);
				cat = [];
				date_range = '';
				loadEvents();
            }
        });
		$('#submitForm26').click(function(){
			$('.m26__filters_dropdown').toggleClass('dropdown-is--active');
			$('.m26__filters_dropdown').parent().toggleClass('is--active');
			$('#cb-19-19').attr('checked','checked');

			loadEvents();
		});

    $('body').on('click', function (e) {
      if (
        $('.m26__actions__results').hasClass('is--active') &&
        !$(e.target).closest('.m26__actions__results').length
      ) {
        $('.m26__actions__results').removeClass('is--active');
      }
		});

		$('#yearList26 a').on('click', function (e) {
			year = $(this).attr('data-value');
			loadEvents()
		});

		$('input[name^="catEvents"]').change(function(e) {
			e.preventDefault();
			cat = $('input[name^="catEvents"]:checked').map((i, el) => `'${$(el).val()}'`).get();
		});

		$('input[name^="institute"]').change(function(e) {
			e.preventDefault();
			institute = $('input[name^="institute"]:checked').map((i, el) => `${$(el).val()}`).get();
		});

		$('input[name^="date_period"]').change(function(e) {
			e.preventDefault();
			date_period = $('input[name^="date_period"]:checked').val();
		});

		$('.pagination-26, .m26__footer_pagination').on('click', 'a.nextPage-26, a.prevPage-26', function(e){
			e.preventDefault();
			page = $(this).data('value');
			loadEvents();
		});

		$('.pagination-26 select, .m26__footer_pagination select').on('change', function(e){
			e.preventDefault();
			page = $(this).val();
			loadEvents();
		});
  };
})(jQuery, app, _);
