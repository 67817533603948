(function ($, app, _) {
  'use strict';
  app.m52 = function () {

    if( $('section.m52').length == 0 ) return;

    let options = {
      valueNames: [ 
        'title', 
        { data: ['id'] },
        { data: ['sorttitle'] },
        { data: ['date'] },
        { data: ['method'] },
        { data: ['phase'] },
        { data: ['stakeholders'] }
      ]
    };

    let options_filter      = { itemSelectText: '', shouldSort: false, searchFields: ['label'] }
    let options_sort        = { itemSelectText: '', shouldSort: false, searchEnabled: false }

    let practices            = new List('m52_practices', options);
    let choices_sort         = new Choices($('select[name="ois-sort"]')[0], options_sort)
    let choices_method       = new Choices($('select[name="ois-filter-method"]')[0], options_filter)
    let choices_phase        = new Choices($('select[name="ois-filter-phase"]')[0], options_filter)
    let choices_stakeholders = new Choices($('select[name="ois-filter-stakeholders"]')[0], options_filter)

    practices.sort('sorttitle', { 'order': 'asc' });
    updateCount();

    $('select[name="ois-sort"]').on('change', function() {
      let value = $(this).val()
      let order = ( value == 'date' ) ? 'desc' : 'asc'

      practices.sort(value, { 'order': order });
      setTimeout( updateCount, 10 );
    });

    $('input[name="ois-search"]').on('keyup', function() {
      filterPractices();
      setTimeout( updateCount, 10 );
    });

    $('select[name^="ois-filter-"]').on('change', function() {
      filterPractices();
      setTimeout( updateCount, 10 );
    });

    $('.m52 .reset').on('click', function() {
      // reset fields
      $('input[name="ois-search"]').val('')
      choices_sort.setChoiceByValue('') 
      choices_method.setChoiceByValue('') 
      choices_phase.setChoiceByValue('') 
      choices_stakeholders.setChoiceByValue('') 
      
      // reset filter
      practices.filter();
      practices.search();
      
      // reset sort
      practices.sort('sorttitle', { 'order': 'asc' });

      // update counter
      setTimeout( updateCount, 10 );
    });

    $('.m52 .toggleAll').on('click', function() {
      $('.practices .list').addClass('showAll');
    });

    $('.m52 .sidebar_toggle').on('click', function() {
      $('.sidebar').toggleClass('showForm');
    });

    function filterPractices() {
      let val_search       = $('input[name="ois-search"]').val().toLowerCase()
      let val_method       = $('select[name="ois-filter-method"]').val()
      let val_phase        = $('select[name="ois-filter-phase"]').val()
      let val_stakeholders = $('select[name="ois-filter-stakeholders"]').val()
      
      practices.filter(function(item) { 
        if( item.values().title.toLowerCase().indexOf(val_search) == -1 ) return false
        if( item.values().phase.indexOf(val_phase)                == -1 ) return false
        if( item.values().method.indexOf(val_method)              == -1 ) return false
        if( item.values().stakeholders.indexOf(val_stakeholders)  == -1 ) return false
        
        return true
      }); 
    }

    function updateCount() {
      let html = ( practices.visibleItems.length == practices.items.length ) ? practices.visibleItems.length + ' good practices' : practices.visibleItems.length + ' of ' + practices.items.length + ' good practices'

      if( document.documentElement.lang == 'de-DE' ) {
        html = ( practices.visibleItems.length == practices.items.length ) ? practices.visibleItems.length + ' Good Practices' : practices.visibleItems.length + ' von ' + practices.items.length + ' Good Practices'
      } 

      $('.sidebar .count').html( html )
      $('.toggleAll').toggle( practices.visibleItems.length > 4 )
      $('.no-practices-found').toggle( practices.visibleItems.length <= 0 )
    }
  };
})(jQuery, app, _);
