(function ($, app, _) {
  'use strict';
  app.m13 = function () {
    var $btn = $('.m13__btn');
    var $modal = $('.modal-m13');
    var $searchInput = $('input[name=search]');
    var qsRegex  = '';

    $btn.click(function (e) {
      e.preventDefault();
      $modal.toggleClass('is--active');
    });

    // init Isotope
    var $grid = $('.m13__list').isotope({
      itemSelector: '.m13__item',
      filter: function() {
        if(!qsRegex){
          return true;
        }

        return $(this).text().match( qsRegex );
      }
    });

    
    // use value of search field to filter
    $searchInput.keyup( debounce( function() {
      qsRegex = new RegExp( `${$(this).val()}`, 'gi' );
      $grid.isotope();
    }, 200 ) );

    // debounce so filtering doesn't happen every millisecond
    function debounce( fn, threshold ) {
      var timeout;
      threshold = threshold || 100;
      return function debounced() {
        clearTimeout( timeout );
        var args = arguments;
        var _this = this;
        function delayed() {
          fn.apply( _this, args );
        }
        timeout = setTimeout( delayed, threshold );
      };
    }

  };
})(jQuery, app, _);
