(function ($, app, _) {
  'use strict';
  app.m35 = function () {
		var $header = $('.main_header'),
		$headerSearchButton = $('.error-page-right-search'),
		$headerSearch = $('.header_search'),
		$searchInput = $('.search__input');

		$headerSearchButton.on('click', function () {
      $headerSearch.toggleClass('is--toggled');
      $header.toggleClass('disable-hide hide');
      $searchInput.val('');
      if ($headerSearch.hasClass('is--toggled')) {
        $searchInput.focus();
      }
    });
  };
})(jQuery, app, _);
