(function ($, app, _) {
  'use strict';
  app.m28 = function () {
    var $slickSliderPressphoto = $('.m28 .m28__slider');

    $slickSliderPressphoto
      .on('init', function (event, slick) {
        if(slick.slideCount <= 1) return;
        $(this).append(
          '<div class="slick-counter-quotes"><span class="current-quote"></span>/<span class="total-quotes"></span></div>'
        );
        $('.m28 .current-quote').text(slick.currentSlide + 1);
        $('.m28 .total-quotes').text(slick.slideCount);
      })
      .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $('.m28 .current-quote').text(nextSlide + 1);
      });

    $slickSliderPressphoto.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: true,
      dots: false,
      infinite: false,
    });
  };
})(jQuery, app, _);
