(function ($, app, _) {
  'use strict';
  app.m67Filter = function () {

    if( $('section.m67').length == 0 ) return;

    let areaSlider = new rSlider({
      target: '#m67__slider',
      values: interpolateMonths(slider__min, slider__max),
      step: 1,
      set: [slider__min, slider__max],
      range: true,
      scale: false,    
      labels: false,
      onChange: function( vals ) {
        filterProjects();
        setTimeout( updateCount, 10 );
      }
    });


    let options = {
      valueNames: [ 
        'title', 
        { data: ['id'] },
        { data: ['search'] },
        { data: ['sort'] },
        { data: ['category'] },
        { data: ['from'] },
        { data: ['until'] },
        // { data: ['sorttitle'] },
        // { data: ['date'] },
        // { data: ['topic'] },
        // { data: ['focus'] },
        // { data: ['funding'] },
        // { data: ['method'] }
      ]
    };

    let projects = new List('m67_projects', options)

    // reset sort
    // projects.sort('sort', { 'order': 'asc' })

    filterProjects();
    setTimeout( updateCount, 10 );

    $('input[name="m67-search"]').on('keyup', function() {
      filterProjects();
      setTimeout( updateCount, 10 );
    });

    $('select[name^="m67-filter-"]').on('change', function() {
      filterProjects();
      setTimeout( updateCount, 10 );
    });

    $('.m67_reset, .m67_filter_reset').on('click', function() {
      // reset fields
      $('input[name="m67-search"]').val('')
      $('select[name^="m67-filter-"]').val('')
      
      // reset filter
      projects.filter()
      projects.search()

      // reset slider
      areaSlider.setValues(slider__min, slider__max)
      
      // update counter
      setTimeout( updateCount, 10 )
    });

    $('.m67 .toggleAll').on('click', function() {
      $('.projects .list').addClass('showAll');
    });

    $('.m67 .sidebar_toggle').on('click', function() {
      $('.sidebar').toggleClass('showForm');
    });

    function filterProjects() {
      let [from, until] = areaSlider.getValue().split(',')
                  from  = convertToUnixTimestamp(from)
                  until = convertToUnixTimestamp(until)

      let val_search    = $('input[name="m67-search"]').val().toLowerCase()
      let val_category  = $('select[name="m67-filter-category"]').val()
      // let val_funding = $('select[name="m67-filter-funding"]').val()
      // let val_method  = $('select[name="m67-filter-method"]').val()
      
      projects.filter(function(item) { 
        let item_from  = convertToUnixTimestamp(item.values().from.replace('-', '/'))
        let item_until = convertToUnixTimestamp(item.values().until.replace('-', '/'))
        
        if( item.values().search.toLowerCase().indexOf(val_search) == -1 ) return false
        if( item.values().category.indexOf(val_category)           == -1 ) return false
        if( item_from < from || item_until > until )                       return false
        // if( val_funding != '' && item.values().funding != val_funding    ) return false
        // if( item.values().method.toLowerCase().indexOf(val_method) == -1 ) return false
        
        return true
      }); 
    }

    function updateCount() {
      let visible = projects.visibleItems.length
      let total   = projects.items.length

      $('.m67_results_count').text( visible )
      $('.m67_filter_reset').toggleClass( 'visible', visible < total )
      $('.m67_empty').toggle( visible <= 0 )
    }

    function convertToUnixTimestamp(dateStr) {
      const [month, year] = dateStr.split("/").map(Number)
      const date          = new Date(year, month - 1)
      const unixTimestamp = Math.floor(date.getTime() / 1000)

      return unixTimestamp
    }

    function interpolateMonths(minDate, maxDate) {
      // Helper function to parse the date string into an object {month, year}
      function parseDate(dateStr) {
          const [month, year] = dateStr.split('/').map(Number);
          return { month, year };
      }

      // Helper function to format the date object back into the "MM-YYYY" string
      function formatDate({ month, year }) {
          return `${String(month).padStart(2, '0')}/${year}`;
      }

      const startDate = parseDate(minDate);
      const endDate = parseDate(maxDate);
      const result = [];

      let currentYear = startDate.year;
      let currentMonth = startDate.month;

      // Loop until we reach the end date
      while (currentYear < endDate.year || (currentYear === endDate.year && currentMonth <= endDate.month)) {
          result.push(formatDate({ month: currentMonth, year: currentYear }));

          // Move to the next month
          currentMonth++;

          // If the month exceeds 12, go to the next year
          if (currentMonth > 12) {
              currentMonth = 1;
              currentYear++;
          }
      }

      return result;
    }
  };
})(jQuery, app, _);
