(function ($, app, _) {
  'use strict';
  app.header = function () {
    var $header = $('.main_header'),
      $desktopHamburger = $('.headerWrap__hamburger'),
      $sideMenu = $('.side_menu'),
      $sideMenuClose = $('.side_menu__close'),
      $navWrapper = $('.nav_wrapper'),
      $responsiveMenuToggler = $('.headerWrap__menu_toggler'),
      $responsiveTab = $('.side_menu__wrapper__mobile h3'),
      $responsiveSideMenuItem = $('.side_menu__item');

    //Desktop
    $desktopHamburger.on('click', function () {
      $(this).toggleClass('is--toggled');
      $sideMenu.toggleClass('is--toggled');
    });
    $sideMenuClose.on('click', function () {
      $sideMenu.toggleClass('is--toggled');
      // $header.removeClass('disable-hide hide');
    });

    //Responsive
    $responsiveMenuToggler.on('click', function () {
      if (window.innerWidth >= 1200) {
        return;
      }
      $(this).toggleClass('is--toggled');
      $navWrapper.toggleClass('is--toggled');
      $header.toggleClass('disable-hide hide');
    });
    $('.menu-item-has-children > a').on('click', function (e) {
      $(this).parent().toggleClass('is--open');
      if(window.innerWidth < 1200 && $(this).parent().hasClass('menu-item-has-children')){
        e.preventDefault();
      }
    });
    
    $responsiveTab.on('click', function () {
      if (window.innerWidth >= 1200) {
        return;
      }
      $responsiveTab.removeClass('is--active');
      $(this).addClass('is--active');
      $responsiveSideMenuItem.removeClass('is--active');
      $('.side_menu__item[data-item="'+$(this).attr('data-item')+'"]').addClass('is--active'); //prettier-ignore
    });

    $('body').on('click', function (e) {
      if (
        $sideMenu.hasClass('is--toggled') &&
        !$(e.target).closest('.side_menu__wrapper').length &&
        !$(e.target).closest('.side_menu__close').length &&
        !$(e.target).closest('.headerWrap__hamburger').length
      ) {
        $desktopHamburger.removeClass('is--toggled');
        $sideMenu.toggleClass('is--toggled');
        // $header.removeClass('disable-hide hide');
      }
    });

    // set active links
    $('.menu > li.current-menu-parent').each(function() {
      $( this ).addClass('is--open')
    });

    // Close menu when clicking outside of menu
    $(window).scroll(function (e) {
      if ($('body').hasClass('menu--toggled')) {
        $('body').removeClass('menu--toggled');
      }
    });
  };
})(jQuery, app, _);
