(function ($, app, _) {
  'use strict';
  app.m42 = function () {
    var $showMoreBtn = $('.m20__box__link');
    $showMoreBtn.on('click', function(e){
      $('.hero-for-series-book-limit').toggleClass('is--toggled')
      $showMoreBtn.toggleClass('is--rotated');
    });
  };
})(jQuery, app, _);
