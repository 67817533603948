(function ($, app, _) {
  'use strict';
  app.search = function () {
    var $header = $('.main_header'),
      $headerSearchButton = $('.headerWrap__search'),
      $headerSearch = $('.header_search'),
      $searchInput = $('.search__input'),
      $searchInputClear = $('.search__header__clear'),
      $searchResults = $('.search__header__resutls');

    $headerSearchButton.on('click', function () {
      $headerSearch.toggleClass('is--toggled');
      $header.toggleClass('disable-hide hide');
      $searchInput.val('');
      if ($headerSearch.hasClass('is--toggled')) {
        $searchInput.focus();
      }
    });

    $searchInput.on('keyup', function () {
			var value = $(this).val(),
        visible = $searchInputClear.hasClass('is--active');
      if (value.length < 3 && visible) {
        $searchInputClear.removeClass('is--active');
        $searchResults.hide('fast');
			}
      if (value.length >= 3) {
				$.ajax({
					type: "GET",
					url: rest_api_js_vars.admin_url,
					data: { action: 'data_fetch', s: value },
					success: function (data) {
						$searchInputClear.addClass('is--active');
						$searchResults.html(data).show('fast');
            var btn = $searchResults.find('.btn-primary');
            var btnHref = btn.attr('href').split('=')[0];
            btn.attr('href', btnHref + '=' + value);
					},
					error: function (XMLHttpRequest, textStatus, errorThrown) {
					},
				});
			 }
    });

    $searchInputClear.on('click', function (e) {
      e.preventDefault();
      if (!$searchResults.is(':visible')) {
        return;
      }
      $searchInput.val('');
      $searchResults.hide('fast');
      $(this).removeClass('is--active');
    });

    $('body').on('click', function (e) {
			if($(e.target).hasClass('error-page-right-search')){
				return;
			}
			
      if (
        $headerSearch.hasClass('is--toggled') &&
        !$(e.target).closest('.search__header').length &&
        !$(e.target).closest('.headerWrap__search').length
      ) {
        $headerSearch.toggleClass('is--toggled');
        $header.removeClass('disable-hide hide');
        $searchInput.val('');
      }
    });
  };
})(jQuery, app, _);
