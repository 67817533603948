(function ($, app, _) {
  'use strict';
  app.m57 = function () {

    if( $('section.m57').length == 0 ) return;

    let options = {
      valueNames: [ 
        'title', 
        { data: ['id'] },
        { data: ['search'] },
        { data: ['sorttitle'] },
        { data: ['date'] },
        { data: ['topic'] },
        { data: ['focus'] },
        { data: ['funding'] },
        { data: ['method'] }
      ]
    };


    let options_filter  = { itemSelectText: '', shouldSort: false, searchFields: ['label'] }
    let options_sort    = { itemSelectText: '', shouldSort: false, searchEnabled: false }

    let projects        = new List('m57_projects', options);
    let choices_sort    = new Choices($('select[name="ois-sort"]')[0], options_sort)
    let choices_topic   = new Choices($('select[name="ois-filter-topic"]')[0], options_filter)
    let choices_method  = new Choices($('select[name="ois-filter-method"]')[0], options_filter)
    let choices_funding = new Choices($('select[name="ois-filter-funding"]')[0], options_filter)

    projects.sort('date', { 'order': 'desc' });
    filterProjects();
    setTimeout( updateCount, 10 );

    $('select[name="ois-sort"]').on('change', function() {
      let value = $(this).val()
      let order = ( value == 'date' ) ? 'desc' : 'asc'

      projects.sort(value, { 'order': order });
      setTimeout( updateCount, 10 );
    });

    $('input[name="ois-search"]').on('keyup', function() {
      filterProjects();
      setTimeout( updateCount, 10 );
    });

    $('select[name^="ois-filter-"]').on('change', function() {
      filterProjects();
      setTimeout( updateCount, 10 );
    });

    $('.m57 .reset').on('click', function() {
      // reset fields
      $('input[name="ois-search"]').val('')
      choices_sort.setChoiceByValue('') 
      choices_topic.setChoiceByValue('') 
      choices_method.setChoiceByValue('') 
      choices_funding.setChoiceByValue('') 
      
      // reset filter
      projects.filter();
      projects.search();
      
      // reset sort
      projects.sort('date', { 'order': 'desc' });

      // update counter
      setTimeout( updateCount, 10 );
    });

    $('.m57 .toggleAll').on('click', function() {
      $('.projects .list').addClass('showAll');
    });

    $('.m57 .sidebar_toggle').on('click', function() {
      $('.sidebar').toggleClass('showForm');
    });

    function filterProjects() {
      let val_search  = $('input[name="ois-search"]').val().toLowerCase()
      let val_topic   = $('select[name="ois-filter-topic"]').val()
      let val_funding = $('select[name="ois-filter-funding"]').val()
      let val_method  = $('select[name="ois-filter-method"]').val()

      projects.filter(function(item) { 

        if( item.values().search.toLowerCase().indexOf(val_search) == -1 ) return false
        if( item.values().topic.indexOf(val_topic)                 == -1 ) return false
        if( val_funding != '' && item.values().funding != val_funding    ) return false
        if( item.values().method.toLowerCase().indexOf(val_method) == -1 ) return false
        
        return true
      }); 
    }

    function updateCount() {
      let html = ( projects.visibleItems.length == projects.items.length ) ? projects.visibleItems.length + ' Projekte' : projects.visibleItems.length + ' von ' + projects.items.length + ' Projekte'
      $('.sidebar .count').html( html )
      $('.toggleAll').toggle( projects.visibleItems.length > 4 )
      $('.no-projects-found').toggle( projects.visibleItems.length <= 0 )
    }
  };
})(jQuery, app, _);
