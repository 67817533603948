(function ($, app, _) {
  'use strict';
  app.m67 = function () {
    if( $('section.m67').length == 0 ) return

    // toggle more filters
    $('.m67_filter_more').on('click', function() {
      let state = $('.m67_filter').attr('data-state')
          state = (state == 'all') ? 'open' : 'all'

      $('.m67_filter').attr('data-state', state)
    })

    // toggle filters
    $('.m67_filter_toggle').on('click', function() {
      $('.m67_filter').attr('data-state', 'open')
    })

    // toggle map
    $('.m67_toggle').on('click', function() {
      $('.m67_map').toggleClass('visible')
      $('.m67_toggle').toggleClass('close')
    })

    // toggle camp
    $('.m67_camp_toggle').on('click', function() {
      $(this).parents('.m67_camp').toggleClass('open')

      if( $('.m67_camp.open').length > 0 ) {
        $('.m67_filter').attr('data-state', 'closed')
      }
    });
  };
})(jQuery, app, _);
