(function ($, app, _) {
  'use strict';
  app.m07o = function () {
    var $showMoreBtn = $('.m07o__link');

    $('.m07o').each(function( index ) {
      let height = $(this).find('.m07o__lines-limit').outerHeight()

      if( height > 100 ) {
        $(this).find('.m07o__link').addClass('is--visible')
        $(this).find('.m07o__lines-limit').addClass('is--collapsed')
      }
    });
    
    $showMoreBtn.on('click', function(e){
      var link = $showMoreBtn.find('.link-secondary');
      $('.m07o__lines-limit').toggleClass('is--collapsed')
      $showMoreBtn.toggleClass('is--rotated');
    });
  };
})(jQuery, app, _);
