(function ($, app, _) {
  'use strict';
  app.m40 = function () {
    var $showMoreBtn = $('.publiation-listing-show-more-btn');
		var $searchInput = $('#search-40');
    var $searchInputM = $('#search-40-m');
    var qsRegex  = '';
    var qsRegexCat  = '';
    var $quicksearch  = '';
		var $cat  = '';
		
		$('.dropdown-with-arrow-list a').on('click', function (e) {
      $grid.isotope({
        sortBy: 'title',
        sortAscending: $(this).attr('data-value') == 'ASC'
      });
		});
		
		var $grid = $('.articleHolderItems').isotope({
      itemSelector: '.publication-listing-item',
      getSortData: {
        title: '.team-profiles-content-header-title'
      },
      filter: function() {
        if(!qsRegex && !qsRegexCat){
          return true;
				}
				
				$('.publication-listing-chapter').each((i,el) => {
					if(!$(el).text().match( qsRegex )){
						$(el).addClass('hidden');
					}else{
						$(el).removeClass('hidden');
					}
				});

        return $(this).text().match( qsRegex ) && $(this).text().match( qsRegexCat );
      }
		});
		
		$('input[name^="cat"]').change(function(e) {
      e.preventDefault();
      $cat = $('input[name^="cat"]:checked').map((i, el) => $(el).val()).get().join('|');

      qsRegex = new RegExp( `${$quicksearch}`, 'gi' );
			qsRegexCat = new RegExp( `${$cat}`, 'gi' );
			
      $grid.isotope();
    });

    // use value of search field to filter
    $searchInput.keyup( debounce( function() {
      $quicksearch = $(this).val();

      qsRegex = new RegExp( `${$quicksearch}`, 'gi' );
      qsRegexCat = new RegExp( `${$cat}`, 'gi' );

      $grid.isotope();
    }, 200 ) );

    $searchInputM.keyup( debounce( function() {
      $quicksearch = $(this).val();

      qsRegex = new RegExp( `${$quicksearch}`, 'gi' );
      qsRegexCat = new RegExp( `${$cat}`, 'gi' );

      $grid.isotope();
    }, 200 ) );

    // debounce so filtering doesn't happen every millisecond
    function debounce( fn, threshold ) {
      var timeout;
      threshold = threshold || 100;
      return function debounced() {
        clearTimeout( timeout );
        var args = arguments;
        var _this = this;
        function delayed() {
          fn.apply( _this, args );
        }
        timeout = setTimeout( delayed, threshold );
      };
    }

    $('.loadMoreCat-40').on('click', function () {
      const stateOfLoad = $(this).data('value');
      $(this).parent().prev('ul').find('.sidebar-show-more').each((i,el) => stateOfLoad === 'showLess' ? $(el).removeClass('hidden') : $(el).addClass('hidden'));
      
      //change text of show more element just for demonstration purposes to this demo
      if (stateOfLoad === 'showLess') {
        $(this).text($(this).data('show-less'));
        $(this).next().toggleClass('sidebar-show-more-btn--rotate');
        $(this).data('value','showMore');
      } else {
        $(this).text($(this).data('show-more'));
        $(this).next().removeClass('sidebar-show-more-btn--rotate');
        $(this).data('value','showLess');
      }
		});
		
  };
})(jQuery, app, _);
