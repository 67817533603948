(function ($, app, _) {
  'use strict';
  app.m17 = function () {
    $(document).ready(function(e){
      if($('.mainWrap > section.m17s').length){
        // console.log('object',$('.mainWrap > section.m17s')[0]);
        $($('.mainWrap > section.m17s')[0]).addClass('show-search');
      }
    })
  };
})(jQuery, app, _);