(function ($, app, _) {
  'use strict';
  app.m07 = function () {
    const controls = `
        <div class="plyr__controls">
            <button type="button" class="plyr__control" aria-label="Play, {title}" data-plyr="play">
                <svg class="icon--pressed" role="presentation"><use xlink:href="#plyr-pause"></use></svg>
                <svg class="icon--not-pressed" role="presentation"><use xlink:href="#plyr-play"></use></svg>
            </button>
        </div>
      `;
    const players = Array.from(document.querySelectorAll('.js-player')).map(
      (p) => {
        const iframe = document.createElement('iframe');
        iframe.src = p.getAttribute('data-url');
        iframe.onload = () => {
          new Plyr(p, { controls });
        };
        p.appendChild(iframe);
        return new Plyr(p, { controls });
      }
    );

    $('.fullscreen-btn').on('click', function (e) {
      var currentPlayerIndex = $(this)
        .closest('.therapy_slide')
        .attr('data-slick-index');
      players[currentPlayerIndex].fullscreen.toggle();
    });

    $(document).ready(function(e){
      $('.top-content--left img').each(function(index, el){
        var _el = $(el);
        _el.parent().html(
          '<a class="lightbox-a-tag" href="'+_el.attr('src')+'" data-lightbox="image-inside" data-title="'+_el.attr('alt')+'"><img src="'+_el.attr('src')+'" alt="'+_el.attr('alt')+'"></a>'
        )
      })
    });

    lightbox.option({
      'resizeDuration': 200,
      'wrapAround': true
    })
  };
})(jQuery, app, _);
