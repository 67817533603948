(function ($, app, _) {
  'use strict';
  app.m47 = function () {
    $('.m03.v47 .news-slider').each( function() {

      $(this).on('init', function (event, slick) {
        $(this).append('<div class="slick-counter-news"><span class="current-news"></span>/<span class="total-news"></span></div>');
        $(this).find('.current-news').text(slick.currentSlide + 1);
        $(this).find('.total-news').text(slick.slideCount);
      })
      .on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $(this).find('.current-news').text(nextSlide + 1);
      });

      $(this).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        infinite: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              dots: false,
              arrows: true,
              centerMode: false,
              centerPadding: false
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              dots: false,
              arrows: true,
              centerMode: true,
              centerPadding: false
            }
          }
        ]
      });
    });
  };
})(jQuery, app, _);
